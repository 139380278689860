<app-layout-modal (closed)="close(false)">
  <!-- <div class="modal-content">
    <ion-grid class="ion-padding grid">
      <ion-row class="ion-margin ion-justify-content-center row-title">
        <ion-text class="title">{{'terms.title' | transloco}}</ion-text>
      </ion-row>

      <ion-row id="row-terms" class="ion-margin ion-padding ion-justify-content-evenly row-terms">
        <section *ngFor="let item of terms">
          <ion-text class="subtitle">
            {{item.title | transloco}}
          </ion-text>
          <ion-text class="text">
            {{item.text | transloco}}
          </ion-text>
        </section>
      </ion-row>

      <ion-row class="w-full row-actions">
        <ion-col>
          <ion-button (click)="close(false)" size="large"  class="hover-to-light" fill="outline" style="width: 100%;height: 36px;">
            {{'shared.cancel' | transloco}}
          </ion-button>
        </ion-col>
        <ion-col>
          <ion-button (click)="close(true)" [disabled]="!reachedBottomScroll" color="light" fill="solid" size="large"  class="bold" style="width: 100%;">
            {{'shared.agree' | transloco}}
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div> -->

  <div class="modal-content">

    <ion-grid class="ion-padding grid">
      
      <ion-row class="ion-margin ion-justify-content-center row-title">
        <ion-text class="title">Aceptación de Reglamento y Oferta de Subasta</ion-text>
      </ion-row>

      <!-- TyC -->

      <ion-row id="row-terms" class="ion-margin ion-padding ion-justify-content-evenly row-terms">              
        <section>
          <h4>
            Reglamento
          </h4>
          <ion-text class="text">
            El presente Reglamento regirá todo el proceso de participación, adjudicación, pago y entrega de los activos digitales, artículos y/o experiencias que serán sometidos a un proceso de adquisición por Subasta Electrónica a través del Sitio, así como los derechos, obligaciones y responsabilidades de cada una de las partes.
            La Aceptación/Suscripción del presente Reglamento reviste el carácter de obligatorio para toda aquella persona que quiera participar u ofertar en el proceso de una Subasta Electrónica, CUALQUIER PERSONA QUE NO ACEPTE EL PRESENTE REGLAMENTO, QUE TIENEN CARÁCTER OBLIGATORIO Y VINCULANTE, DEBERÁ ABSTENERSE DE EFECTUAR OFERTA POR LOS ELEMENTOS SUBASTADOS.
            El Usuario u Oferente se obliga a cumplir con todas las leyes, estatutos, reglamentos y regulaciones aplicables, y con las disposiciones contenidas en los Términos y Condiciones de uso del Sitio y en las siguientes Cláusulas:
          </ion-text>
        </section>

        <section>
          <h4 class="">
            1. Definiciones.
          </h4>
          <ion-text class="text">
            En este Reglamento, en adición a los términos definidos a lo largo del cuerpo del mismo y son resaltados con letra negrita y entre comillas y los que son definidos en los Términos y Condiciones de Uso del Sitio, las Partes acuerdan que los siguientes términos tendrán el significado específico en cada caso asignado:
            <br><b>“Artista u Ofertante”:</b> Es el creador y dueño del contenido disponible en el Sitio. El Artista en su rol de Ofertante en las Subastas Electrónicas es quien pone a disposición un activo digital, artículo y/o experiencia con la intención de venderlo al mejor postor.
            <br><b>“Billetera Criptográfica”:</b> significa un software, tanto en versión browser-based como mobile, que permite al Usuario gestionar y custodiar las direcciones, llaves públicas y llaves privadas unívocamente asociadas a uno o varios Tokens de propiedad del Usuario y con las que: (i) es posible operar en la Red de Blockchain; y (ii) se permite disponer los Tokens transfiriendose a Sub-Adquirentes (si llegaren a existir).
            <br><b>“Días Hábiles”:</b> significa días hábiles todos los días del año, con excepción de los sábados, domingos y feriados en la República Argentina o los declarados inhábiles por leyes, decretos y resoluciones especiales en las jurisdicciones mencionadas.
            <br><b>“Martillero”:</b> persona que actúa a los fines de garantizar la venta en subasta de los elementos ofertados, es el encargado de dotar de formalidad a la subasta, aceptar ofertas y anunciar al ganador. 
            <br><b>“Oferente”:</b> es el Usuario en su rol de postor en una Subasta Electrónica.
            <br><b>“Operador”:</b> FAN Network Argentina S.A.S., o quien en un futuro la reemplace, es quien opera el Sitio en relación a las Subastas Electrónicas poniendo a disposición su expertise para que los Usuarios registrados puedan en el ecosistema de Dillom adquirir, comprar, coleccionar, vender, subastar, intercambiar, donar, regalar, transferir y/o disponer de otra manera activos digitales, articulos y/o experiencias.
            <br><b>“Red de Blockchain”:</b> significa la red Polygon Technology, una especie de tecnología de registros distribuidos públicos no permisionados, que agrupa datos en bloques y utiliza criptografía asimétrica para identificar a sus usuarios. Queda perfectamente entendido que Polygon Technology (i.e. la Red de Blockchain) no es un desarrollo propio ni es de propiedad de FAN Network Argentina S.A.S. y se la utiliza para registrar la creación e intercambio de Tokens o demás Activos Virtuales. Según su exclusivo criterio y por razones técnicamente fundadas, FAN Network Argentina S.A.S. se reserva el derecho de modificar, suplementar, replicar o cambiar la Red de Blockchain por otras redes de registro distribuido, debiendo en su caso dar preaviso razonable al Adquirente y, en su caso, migrar los Tokens.
            <br><b>“Sitio”:</b> significa, conjunta o alternativamente según el caso, el portal de Internet dispuesto por Dillom y operado por FAN Network Argentina S.A.S. que, sujeto a sus términos y condiciones generales de uso, permite al Usuario: (i) manifestar su interés por adquirir activos digitales, articulos y/o experiencias que los Artistas ponen a disposición; (ii) adquirir los activos digitales, articulos y/o experiencias; y (iii) gestionar y disponer de sus activos digitales, articulos y/o experiencias con arreglo a los términos y condiciones del Sitio.
            <br><b>“Sub-Adquirente(s)”:</b> significa la persona que: (i) se ha autenticado a través del Sitio, acompañando la documentación legalmente exigible que respalda su eventual participación como Usuario u Oferente; (ii) ha manifestado interés en adquirir activos digitales, articulos y/o experiencias de otro Usuario; (iii) ha acordado a través del Sitio con otro Usuario un precio para la adquisición de activos digitales, articulos y/o experiencias, cumplimentando con los mecanismos dispuestos y sus costos.
            <br><b>“Token(s)”:</b> significa un registro criptográfico asimétrico en la Red de Blockchain que representa activos digitales, articulos y/o experiencias ofertados por los Artistas y que podrá tener atributos distintos en función del diseño específico de cada Token, como a modo de ejemplo, ser un Token fungible o no fungible, transferible o no transferible, con fecha de vencimiento determinada o determinable, y con finalidades que pueden incluir tanto la  identificación y autenticación de un Usuario y, eventualmente, permitir acceder a beneficios especiales otorgados por el Artista, FAN Network Argentina S.A.S. o sus socios comerciales.
            <br><b>“Usuario”:</b> persona registrada en el Sitio y que se vale del acceso para utilizar las funciones y características proporcionadas por este.
          </ion-text>
        </section>  
        
        <section>
          <ion-text class="subtitle">
            2. Capacidad.
          </ion-text>
          <ion-text class="text">
            Los Servicios provistos por el Sitio solo están disponibles para personas física o jurídica que tengan y mantengan capacidad legal para contratar y ser contratados de acuerdo a la normativa vigente. Para ello cualquier interesado deberá cumplimentar con el proceso de registración previsto en los Términos y Condiciones de Uso del Sitio. No podrán utilizar los servicios las personas que no tengan dicha capacidad o Usuarios que hayan sido suspendidos temporalmente o inhabilitados definitivamente. Si se registrara en el Sitio un Usuario en carácter de representante de una persona jurídica, deberá tener capacidad para contratar a nombre de tal entidad y de obligar a la misma en los términos de los Términos y Condiciones de Uso del Sitio y del presente Reglamento.
          </ion-text>
        </section>  

        <section>
          <ion-text class="subtitle">
            3. Registración. Documentación.
          </ion-text>
          <ion-text class="text">
            3.1 Todo Usuario que desee formular una oferta por los elementos subastados en el Sitio debe previamente haber cumplimentado el proceso de registración en el Sitio, para ello deberá haber completado el formulario de registro en todos sus campos con datos válidos e información personal exacta, precisa y verdadera los cuales revisten el carácter de declaración jurada, y serán validados y aprobados por el Operador antes de otorgarle los permisos de acceso y uso del Sitio.
            El Operador no se responsabiliza por la certeza y validez de los datos del Oferente provistos a los fines del Sitio, ni por su falta de actualización. Los Oferentes garantizan y responden, en cualquier caso, de la veracidad, exactitud, integridad, vigencia y autenticidad de la Información ingresada. El Operador se reserva asimismo el derecho de rechazar una oferta o de cancelar o suspender, temporal o definitivamente una cuenta o la participación en una subasta, en caso de detectar incongruencias o inconsistencias en la información provista por un Usuario u Oferente, o en caso de detectar actividades sospechosas, sin que tal decisión genere para el Usuario u Oferente derechos de indemnización o resarcimiento. 
            <br>
            3.2 En cualquier momento el Operador podrá solicitar al Usuario u Oferente, por e-mail o a través de la Billetera Criptográfica la entrega, en los plazos que se indiquen, de los documentos que estime razonablemente necesarios para garantizar los estándares legales en materia de normas anti-lavado, y de su obligación de colaborar activamente para que el Operador o el Artista pueda cumplir la normativa aplicable de conocimiento del cliente (KYC) y de prevención de financiamiento del terrorismo, bajo apercibimiento de ser sancionado de conformidad a la Cláusula 10.
          </ion-text>
        </section> 
                
        <section>
          <ion-text class="subtitle">
            4. Responsabilidades del Operador. 
          </ion-text>
          <ion-text class="text">
            A través del Sitio el Operador administra el mecanismo habilitado para que el Artista u Ofertante ofrezca en subasta bienes de su propiedad, por ello en su exclusivo y excluyente rol de facilitador, se compromete a: (i) garantizar la autorización expresa del Artista y la existencia de los elementos ofertados para disponerlos por Subasta Electrónica; (ii) publicitar los activos digitales, articulos y/o experiencias que el Artista ofrezca para ser subastado tanto en el Sitio como en cualquier otro medio que considere oportuno; (iii) brindar los servicios y la atención a los Artistas para que puedan publicar sus activos digitales, articulos y/o experiencias en cumplimiento de las leyes y reglamentos vigentes y aplicables; (iv) brindar a los Artistas y Usuarios la atención e información necesaria para la correcta realización de la Subasta Electrónica, asistiendolo con los profesionales correspondientes; y (v) asistir las partes en el proceso posterior a la conclusión de la subasta electrónica.
          </ion-text>
        </section>  
                
        <section>
          <ion-text class="subtitle">
            5. Responsabilidades del Artista u Ofertante.
          </ion-text>
          <ion-text class="text">
            El Artista, en su rol de Ofertante en las Subastas Electrónicas se compromete a: (i) garantizar que tiene el derecho legal y la autoridad para ofrecer en subasta el elemento descrito en la oferta, así como garantizar la condición, autenticidad y cualquier otra característica relevante de los elementos subastados, su oferta en el Sitio es vinculante y no puede ser retirada ni modificada una vez ofertada; (ii) autorizar al Operador y en su caso al Martillero para que administren la subasta y ofrezcan los elementos a subastar en el Sitio; (iii) proporcionar una descripción precisa y completa del producto a subastar, incluyendo detalles sobre su estado, características, historial y cualquier defecto conocido o característico especial; cualquier información falsa u omitida puede resultar en la descalificación de la oferta y en acciones legales por parte de los compradores afectados; (iv) entregar el producto al comprador ganador de la subasta en las condiciones acordadas y dentro del plazo estipulado; (v) colaborar, en tiempo y forma, en todo lo que fuere necesario antes y durante la celebración de la subasta electrónica, como una vez concluida a los efectos de entregar los elementos subastados al ganador; y (vi) abstenerse de utilizar el Sitio u ofertar elementos con fines o efectos contrarios a las leyes y reglamentos aplicables, a la moral, al orden público y a las buenas costumbres, o de ofrecer elementos para la subasta prohibidos, inmorales, discriminatorios o que de cualquier forma puedan dañar la reputación del Sitio. 
          </ion-text>
        </section>   
                        
        <section>
          <ion-text class="subtitle">
            6. Responsabilidades del Usuario u Oferente.
          </ion-text>
          <ion-text class="text">
            El Usuario, en su rol de Oferente en las Subastas Electrónicas se compromete a: (i) proveer correctamente sus datos personales y mantenerlos actualizados, a tener capacidad legal para contratar tanto si lo hace en nombre propio o en representación de una persona jurídica; (ii) colaborar, en tiempo y forma, en todo lo que fuere necesario antes y durante la celebración de la subasta electrónica, como una vez concluida si resultare ganador; (iii) a presentar ofertas precisas y veraces durante el proceso de subasta; cualquier información falsa, engañosa o inexacta proporcionada por el Oferente puede resultar en la descalificación de sus ofertas y la prohibición de participar en futuras subastas; (iv) realizar las actividades a su cargo de buena fe y realizar la debida diligencia antes de presentar una oferta; (v) garantizar que una vez que su oferta es aceptada y en caso de que se convierta en la oferta ganadora, está legalmente obligado a completar la transacción, retirarse de la compra después de ganar la subasta puede resultar en sanciones previstas en el presente Reglamento; (vi) abstenerse de utilizar el Sitio con fines o efectos contrarios a las leyes y reglamentos aplicables, a la moral, al orden público y a las buenas costumbres, o de cualquier forma que pueda dañar, inutilizar o deteriorar el Sitio o impedir su normal utilización.
          </ion-text>
        </section>    
                                
        <section>
          <ion-text class="subtitle">
            7. Del Martillero.
          </ion-text>
          <ion-text class="text">
            El Martillero habilitado es un auxiliar en el proceso de Subasta Electrónica y en cumplimiento de la autorización emitida por el Artista y el Operador para que subaste electrónicamente bienes, se compromete a: (i) obtener autorización expresa del Operador y el Artista para intervenir en la Subasta Electrónica de los bienes del Artista; (ii) corroborar los datos y derechos del Ofertante sobre de los activos digitales, artículos y/o experiencia a subastar, y corroborar que se mantengan actualizados, para consulta de los Usuarios; (iii) supervisar la correcta publicación en el Sitio de los activos digitales, artículos y/o experiencia de propiedad del Artista para que el usuario interesado pueda ofertar de manera informada; (iv) supervisar las ofertas efectuadas por los Oferentes, que las mismas cumplan con los estándares necesarios para ser válida y en su caso vinculante; (v) colaborar en todo lo que fuere necesario antes y durante la celebración de la Subasta Electrónica como una vez concluida; (vi) revestir de las formalidades requeridas al acto de adjudicación, garantizando la transparencia del proceso; y (vii) realizar las actividades a su cargo de manera diligente, correcta, y lícitamente.
          </ion-text>
        </section>     
                                        
        <section>
          <ion-text class="subtitle">        
            8. Formas.
          </ion-text>
          <ion-text class="text">
            <b>8.1 Subasta Electrónica tradicional.</b> El Operador, con supervisión del Martillero, publicará en el Sitio los activos digitales, artículos y/o experiencias ofertados -por orden y cuenta del Artista-, la duración del acto, el precio base y los incrementos mínimos de oferta, de la subasta. Específicamente se indicará la hora inicial y final las que serán publicadas en todo tiempo en el Sitio, oportunidad donde los Usuarios podrán ingresar sus ofertas, previo a realizarse una oferta el Sitio informará a cada Oferente el monto final correspondiente a su oferta, detallando el monto ofertado, los gastos, impuestos y costas a su cargo. Finalizará el acto el día y hora fijado, siempre que en el Sitio no se ingresarán ofertas de último minuto, en cuyo caso se prorrogará por un minuto adicional sucesivamente hasta que no se realicen nuevas ofertas durante un minuto.  En el supuesto que el Sitio no resulte operativo al momento del cierre del acto de subasta, se prorrogará por el plazo que el Operador y el Martillero dispongan, lo que será comunicado por los canales establecidos a todos los Oferentes y a través del Sitio a los Usuarios en general. Concluido el acto de subasta y determinado el ganador, el Operador informará al Artista y al Oferente ganador el resultado de la subasta por los medios establecidos en la Cláusula 13. Asimismo, se le proporcionarán instrucciones sobre los pasos siguientes. 
            El adjudicatario deberá, en caso de corresponder, cancelar el monto ofertado dentro del plazo de cuarenta y ocho (48) horas, a través de las modalidades habilitadas en el Portal. En caso de que su oferta se realice a través de tarjeta de crédito, los montos de indisponer hasta tanto su oferta no sea superada por 2 Oferentes diferentes. Verificado el pago, el Operador se contactará con el Oferente ganador para proceder a la entrega del elemento subastado y a la firma de la documentación necesaria para su transferencia. No verificado el pago por el adjudicatario, el Operador, con supervisión del Martillero, podrá dar aviso al segundo mejor postor, para que en el caso que mantenga su interés en la compra, efectúe el pago de su oferta; si ninguno de los dos mejores postores abona el bien, la subasta será declarada desierta, pudiendo volver a subastarse electrónicamente.
            <br><b>8.2 Subasta Electrónica a ciegas.</b> El Operador, con supervisión del Martillero, publicará en el Sitio los activos digitales, articulos y/o experiencias ofertados por orden y cuenta del Artista, y la duración del acto de subasta para cada elemento. Antes del inicio de la subasta se establecerá el precio mínimo del elemento a subastar, siendo el mismo confidencial. 
            El acto de subasta se iniciará el día y hora designado, oportunidad en que cada Usuario podrá ingresar sus ofertas a ciegas en el Sitio, los Oferentes podrán realizar múltiples ofertas a ciegas cada una de las cuales deberá superar la oferta anterior acorde a los incrementos predeterminados, previo a realizarse una oferta el Sitio informará a cada Oferente el monto final correspondiente a su oferta, detallando el monto ofertado, los gastos, impuestos y costas a su cargo. Las ofertas son confidenciales y no se revelan a otros participantes en tiempo real.  Finalizará el acto el día y hora fijado. En el supuesto que el Sitio no resulte operativo al momento del cierre del acto de subasta, se prorrogará por el plazo que el Operador y el Martillero dispongan, lo que será comunicado por los canales establecidos a todos los Oferentes y a través del Sitio a los Usuarios en general. Una vez que finaliza el tiempo designado para la subasta, el Operador ordenará automáticamente las ofertas en función de su valor, sin revelar la identidad de los Oferentes. La oferta más alta ganadora es aquella que excede el precio base y supera a todas las demás ofertas.  Después de que se ha determinado la oferta ganadora, se notificará al Artista y a todos los Oferentes el resultado de la subasta por los medios establecidos en la Cláusula 13, detallando la o las ofertas ganadoras. Asimismo, se informará al Oferente ganador y se le proporcionarán instrucciones sobre los pasos siguientes. 
            El adjudicatario deberá, en caso de corresponder, cancelar el monto ofertado dentro del plazo de cuarenta y ocho (48) horas, a través de las modalidades habilitadas en el Portal. Verificado el pago, el Operador se contactará con el Oferente ganador para proceder a la entrega del elemento subastado y a la firma de la documentación necesaria para su transferencia. No verificado el pago por el adjudicatario, el Operador, con supervisión del Martillero, podrá dar aviso al segundo mejor postor, para que en el caso que mantenga su interés en la compra, efectúe el pago de su oferta; si ninguno de los dos mejores postores abona el bien, la subasta será declarada desierta, pudiendo volver a subastarse electrónicamente.
          </ion-text>
        </section> 

        <section>
          <ion-text class="subtitle">
            9. Entrega de activos digitales, artículos y/o experiencias.
          </ion-text>
          <ion-text class="text">
            El Oferente reconoce y acepta que es de su exclusiva y excluyente responsabilidad coordinar con el Operador la entrega de los elementos adquiridos, y en su defecto seleccionar y contratar el transporte correspondiente. Por ello, el adjudicatario libera de toda responsabilidad y mantendrá indemne al Operador por cualquier reclamo de cualquier índole vinculado a la entrega y transporte de los bienes.
          </ion-text>
        </section>     
        
        <section>
          <ion-text class="subtitle">
            10. Sanciones. 
          </ion-text>
          <ion-text class="text">
            Cualquier incumplimiento por parte del Usuario u Oferente a las cláusulas del presente Reglamento, de los Términos y Condiciones de uso del Sitio, o la detección de actividades sospechosas en términos de KYC/AML dentro o fuera del Sitio, dará al Operador -sin necesidad de aprobación del Usuario u Oferente- la facultad de restringir, suspender o bloquear transitoria o definitivamente su acceso al Sitio, el uso de la Cuenta, o la disposición de los elementos adquiridos por Subasta Electrónica, sin que tal decisión genere para el Usuario u Oferente derecho de indemnización o resarcimiento alguno en tanto el proceder del Operador se encuentre debidamente justificado.
          </ion-text>
        </section> 
                
        <section>
          <ion-text class="subtitle">
            11. Incumplimiento. Cláusula penal.
          </ion-text>
          <ion-text class="text">
            El Operador quedará habilitado para reclamar al adjudicatario que no integrare los montos de su oferta ganadora, en concepto de cláusula penal por incumplimiento, la suma equivalente al veinte por ciento (20%) del valor ofertado, más comisiones, impuestos y costos, en ejercicio de lo dispuesto por los Artículos 974 y 790 siguientes y concordantes del Código Civil y Comercial de la Nación.
          </ion-text>
        </section>
                        
        <section>
          <ion-text class="subtitle">
            12. Misceláneas.
          </ion-text>
          <ion-text class="text">
            12.1. El Usuario u Oferente deberá leer atenta y comprensivamente el presente Reglamento cada vez formule una oferta, ya que pueden sufrir modificaciones, las que serán debidamente anunciadas y notificadas. El Operador podrá modificar estos Términos y Condiciones en cualquier momento mediante la publicación en el Sitio de los términos modificados, detallando los cambios efectuados, los que serán claramente delimitados. Todos los términos modificados entrarán en vigor desde el momento de su publicación (o en la fecha posterior que en cada caso se consigne) y se presumirán aceptados por el Oferente con el mero uso del Sitio después de cualquier modificación del Reglamento. Las modificaciones efectuadas no podrán ser aplicadas a subastas ya iniciadas salvo que exista consentimiento expreso de todos los Oferentes.
            <br>12.2 En el caso de que una o más de las disposiciones de este Reglamento se consideren por cualquier motivo inválidas, ilegales o inaplicables, en su totalidad o en parte, sólo dichas disposiciones se considerarán nulas y no afectarán ninguna otra disposición de este Reglamento, y las disposiciones restantes permanecerán en pleno vigor y exigibles entre las Partes.
            <br>12.3. Las firmas electrónicas de los Oferentes que se hallaren introducidas mediante la operación del Sitio y de la Billetera Criptográfica como manifestación de su voluntad no podrán ser repudiadas.
          </ion-text>
        </section>
                        
        <section>
          <ion-text class="subtitle">
            13. Notificaciones.
          </ion-text>
          <ion-text class="text">
            Las Partes se obligan a notificar de manera fehaciente el cambio de domicilio constituido y/o electrónico, y en caso de no ser debidamente notificado el mismo, se tendrá por válido a todos los efectos legales el anteriormente constituido. Las Partes acuerdan dar pleno efecto jurídico a las notificaciones enviadas a través de los domicilios electrónicos constituidos en el encabezamiento de este Contrato, incluyendo expresamente las notificaciones cursadas a través de la Billetera Criptográfica.
          </ion-text>
        </section>
                                
        <section>
          <ion-text class="subtitle">
            14. Ley aplicable. Competencia
          </ion-text>
          <ion-text class="text">
            Ambas partes acuerdan que el presente Reglamento está sujeto a las leyes argentinas, renunciando a la aplicación de cualquier ley extranjera, por lo que cualquier conflicto/reclamación que surja de o en relación con el presente Reglamento serán interpretados y resueltos conforme a las presentes cláusulas, a las cláusulas de los Términos y Condiciones de uso del Sitio, y  luego a las normas del Código Civil y Comercial de la República Argentina y toda otra legislación aplicable.
            De igual forma para todos los efectos legales extrajudiciales y/o judiciales emergentes directa o indirectamente del presente Reglamento las partes, se someten a la jurisdicción de los Tribunales Ordinarios de la Ciudad Autónoma de Buenos Aires, renunciando expresamente a todo otro fuero o jurisdicción que pudiera corresponderles. Siempre que lo permita la legislación aplicable, ambas partes acuerdan que cada uno podrá presentar reclamaciones contra el otro únicamente a título individual y no como demandante o miembro de acciones colectivas.
            <br><br>Vigente a partir del 29 de Agosto de 2023.-
          </ion-text>
        </section>
                                
        <section>
          <h4 class="">
            2. Oferta en subasta a ciegas.
          </h4>
          <ion-text class="text">
            El día de la fecha, el usuario de la plataforma, cuyos datos personales y de contacto fueron proporcionados al momento de iniciar sesión, realiza la presente postura u oferta con condición Resolutoria con el fin de la celebración de la subasta “Post Mortem” - Dillom. Los datos aportados por el usuario al momento de iniciar sesión en la plataforma son tomados como válidos e integran este documento, ya que corresponden al usuario que va a ofertar. El Oferente declara tener plena capacidad jurídica para obligarse. Asimismo, acepta y declara que el presente documento tendrá plenos efectos desde el momento de su adhesión. Las bases de negociación que aquí se establecen tienen el carácter vinculante, son complementarias al Reglamento de Subasta y se regirá por las siguientes cláusulas:
          </ion-text>
        </section>

        <section>
          <ion-text class="subtitle">
            1. Objetos de la subasta:
          </ion-text>
          <ion-text class="text">
              Los objetos puestos a disposición por Dillom (“El Artista”) a ser subastados bajo la
              modalidad subasta a ciegas en el Sitio son: COMBO 1: un (1) Ticket de Ingreso al
              espectáculo “Post Mortem” del Artista Dillom, a realizarse en el Teatro San Martín, sito en
              Av. Corrientes 1530 de C.A.B.A, el día 4 de Septiembre, más tres (3) tarjetas lenticulares
              coleccionables <u>(100 unidades disponibles);</u> COMBO 2: un (1) Ticket de Ingreso al
              espectáculo “Post Mortem” del Artista Dillom, a realizarse en el Teatro San Martín, sito en
              Av. Corrientes 1530 de C.A.B.A, el día 4 de Septiembre, más un (1) Vaso de Diseño
              coleccionable, y un póster coleccionable <u>(100 unidades disponibles);</u> y COMBO 3: un (1)
              Ticket de Ingreso al espectáculo “Post Mortem” del Artista Dillom, a realizarse en el Teatro
              San Martín, sito en Av. Corrientes 1530 de C.A.B.A, el día 4 de Septiembre, más un (1)
              Disco Vinilo del album “Post Mortem” Edición Especial, solo disco <u>(20 unidades disponibles)</u>.
              <u>Cada postura u oferta con condición resolutoria efectuada por el Oferente será en relación
              una unidad y para el Combo seleccionado en el Sitio. Las posturas u ofertas están sujetas a
              la condición resolutoria de que superen la base de la subasta y de que no se produzca una
              oferta mayor. La base de la subasta y las ofertas efectuadas por los Oferentes son
              confidenciales y no se revelan a otros participantes en tiempo real.</u>
          </ion-text>    
        </section>
        
        <section>
          <ion-text class="subtitle">
            2. Proceso de la oferta: 
          </ion-text>
          <ion-text class="text">
            Los Oferentes presentarán sus ofertas, adhiriendo al presente, antes de la hora designada para el cierre de la subasta. Las ofertas se mantendrán en secreto hasta que se determinen los ganadores según cada Combo. Los Oferentes podrán realizar múltiples ofertas a ciegas por un mismo Combo, cada una de las cuales deberá superar la oferta anterior acorde a los incrementos predeterminados, cada nueva oferta invalida la antes efectuada.          
          </ion-text>            
        </section>
                
        <section>
          <ion-text class="subtitle">
            3. Inicio y Cierre de la subasta: 
          </ion-text>
          <ion-text class="text">
            La subasta inicia a las 19hs. del 29 de Agosto de 2023 y se cerrará a las 19hs. del 1 de Septiembre de 2023. En ese momento, ya no se aceptarán más ofertas.
          </ion-text>
        </section>
                        
        <section>
          <ion-text class="subtitle">
            4. Determinación del Ganador:
          </ion-text>
          <ion-text class="text">
            Una vez cerrada la subasta, se revelarán los montos, fechas y horarios de las ofertas presentadas que resulten ganadoras. Se considerarán como ofertas ganadoras a las más altas, que superen las demás ofertas, y excedan el precio base. En caso de que 2 o más posturas u ofertas sean por igual monto se tomará como prioritaria la fecha y hora de la oferta, quien haya ofrecido antes será el ganador.  
            Después de que se hayan determinado las ofertas ganadoras, se notificará a todos los ganadores el resultado de la subasta por los medios establecidos y se le proporcionarán las instrucciones sobre los pasos siguientes.
          </ion-text>
        </section>
                                
        <section>
          <ion-text class="subtitle">
            5. Precio de Compra. Pago.
          </ion-text>
          <ion-text class="text">
            Las posturas u ofertas se harán en pesos, a través de las modalidades habilitadas en el Portal. Previo a realizarse la postura u oferta el Sitio informará a cada Oferente el monto final correspondiente a su oferta. El monto ofertado es el que consta en el comprobante emitido tras finalizar la oferta, el cual es parte integrante de la presente oferta. El pago de la misma se realizará mediante la plataforma de MercadoPago. Una vez ofertado un monto, el mismo más los gastos, impuestos y costas a cargo del Oferente se bloquearán en el medio de pago elegido hasta vencido el plazo de setenta y dos (72) horas hábiles posteriores al de cierre de la subasta y/o el plazo que resultare del normal desarrollo inherente al proceso que corre por cuenta de la app integrada. En caso de no resultar ganador dichos montos no serán debitados del medio de pago seleccionado y estarán nuevamente a disposición del Usuario. En caso de resultar ganador se debitará el monto bloqueado del medio de pago elegido.
            Verificado el pago, el Operador se contactará con el Oferente ganador para proceder a la entrega del elemento subastado y a la firma de la documentación necesaria para su transferencia.
          </ion-text>
        </section>
                                        
        <section>
          <ion-text class="subtitle">
            6. Ganador Suplente. Vacancia.
          </ion-text>
          <ion-text class="text">
            No verificado el pago por el adjudicatario, el Operador, podrá dar aviso al segundo mejor postor, para que en el caso de que mantenga su interés en la compra, efectúe el pago de su oferta; si ninguno de los dos mejores postores abona el bien, la subasta será declarada desierta, pudiendo volver a subastarse electrónicamente.          
          </ion-text>
        </section>
                                                
        <section>
          <ion-text class="subtitle">
            7. Entrega del Objeto: 
          </ion-text>
          <ion-text class="text">
            Los Combos subastados se entregarán a los adjudicatarios el día 4 de Septiembre, en el teatro San Martín, sito en calle sito en Av. Corrientes 1530 de C.A.B.A., de acuerdo con los términos anunciados en la notificación cursada al momento de notificar a los ganadores.
          </ion-text>
        </section>
                                                        
        <section>
          <ion-text class="subtitle">
            8. Confidencialidad: 
          </ion-text>
          <ion-text class="text">
            Toda la información relacionada con las ofertas y los participantes en la subasta se mantendrá confidencial.
          </ion-text>
        </section>
      </ion-row>



      <!-- Botones -->

      <ion-row class="w-full row-actions">
        <ion-col>
          <ion-button (click)="close(false)" size="large"  class="hover-to-light" fill="outline" style="width: 100%;height: 36px;">
            {{'shared.cancel' | transloco}}
          </ion-button>
        </ion-col>
        <ion-col>
          <ion-button (click)="close(true)" [disabled]="!reachedBottomScroll" color="light" fill="solid" size="large"  class="bold" style="width: 100%;">
            {{'shared.agree' | transloco}}
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>



  </div>
</app-layout-modal>