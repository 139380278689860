import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Pack } from '../../../../shared/interface/pack';

@Component({
  selector: 'app-profile-collection',
  templateUrl: './profile-collection.component.html',
  styleUrls: ['./profile-collection.component.scss'],
})
export class ProfileCollectionComponent {
  @Input() packs: Pack[];
  @Input() packsPagination: any;
  @Output() loadMoreData: EventEmitter<any> = new EventEmitter()


  loadData(event) {
    console.log('Done', event);
    setTimeout(() => {
      event.target.complete();
      console.log(this.packsPagination);

      this.loadMoreData.emit(parseInt(this.packsPagination?.current_page) + 1);

      // App logic to determine if all data is loaded
      // and disable the infinite scroll
      if (this.packs.length == this.packsPagination?.total) {
        event.target.disabled = true;
      }
    }, 500);
  }

}
