import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-separator',
  templateUrl: './separator.component.html',
  styleUrls: ['./separator.component.scss'],
})
export class SeparatorComponent implements OnInit {
  @Input() side: string;

  constructor() {
  }

  ngOnInit() {
  }

  scrollToTop() {
    document.getElementById('layoutbg').scrollTo({ top: 0, behavior: 'smooth' });
  }

  scrollToBottom() {
    document.getElementById('layoutbg').scrollTo(
      {
        top: document.getElementById('layoutbg').scrollHeight,
        behavior: 'smooth'
      });
  }

}
