import {UntypedFormControl} from '@angular/forms';

export interface ValidationResult {
  [key: string]: boolean;
}

export class CustomValidator {

  public static hasNumber(control: UntypedFormControl): ValidationResult {
    const hasNumber = /\d/.test(control.value);
    if (!hasNumber) {
      return {hasNumber: true};
    }
    return null;
  }

  public static hasUpper(control: UntypedFormControl): ValidationResult {
    const hasUpper = /[A-Z]/.test(control.value);
    if (!hasUpper) {
      return {hasUpper: true};
    }
    return null;
  }

  public static hasLower(control: UntypedFormControl): ValidationResult {
    const hasLower = /[a-z]/.test(control.value);
    if (!hasLower) {
      return {hasLower: true};
    }
    return null;
  }
}
