import { AbstractControl } from '@angular/forms';

export class MatchPasswordValidation {
    static matchPassword(ac: AbstractControl) {
        const password = ac.get('password')?.value;
        const confirmPassword = ac.get('confirmPassword')?.value;

        if (password !== confirmPassword) {
            ac.get('confirmPassword').setErrors({
                matchPassword: true,
            });
        } else {
            return null;
        }
    }
}
