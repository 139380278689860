<app-layout-modal (closed)="closeModal()">
 
  <div class="modal-content">
    <h2 class="title">{{'auction_modal.have_problem' | transloco}}</h2>
  
    <div class="info">
      <label>{{'auction_modal.payment_declined' | transloco}}</label>
    </div>
  
    <div class="actions">
      <ion-button color="light" fill="outline" size="large" (click)="closeModal()">
        <ion-text class="bold btn-text">{{'shared.cancel' | transloco}}</ion-text>
      </ion-button>
      <ion-button color="light" size="large" (click)="closeModal()">
        <ion-text class="bold btn-text">{{'result_modal.enter_new_card' | transloco}}</ion-text>
      </ion-button>
    </div>

  </div>
</app-layout-modal>
