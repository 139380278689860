import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Pack} from '../shared/interface/pack';
import {Observable} from 'rxjs';
import {BaseResponse} from '../shared/interface/response';
import {CitiesResponse, CountryResponse} from '../shared/interface/country';


@Injectable({
  providedIn: 'root'
})
export class CountryService extends BaseService<Pack> {

  constructor(private httpClient: HttpClient) {
    super(httpClient);
    this.url = `${environment.apiServer}/countries`;
  }

  getCountries(): Observable<BaseResponse<any>> {
    return this.httpClient.get<CountryResponse>(`${this.url}`);
  }

  getCitiesByCountry(countryCode: string): Observable<CitiesResponse> {
    return this.httpClient.get<CitiesResponse>(`${this.url}/${countryCode}/cities`);
  }
}
