import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {BaseResponse, PaginatedResponse} from '../shared/interface/response';
import {MintedPack, PendingBid, User, UserDetail} from '../shared/interface/user';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService<BaseResponse<UserDetail>> {

  constructor(private httpClient: HttpClient) {
    super(httpClient);
    this.url = `${environment.apiServer}/users`;
  }

  mintedPacks(userId: number) {
    return this.httpClient.get<PaginatedResponse<MintedPack[]>>(`${this.url}/${userId}/minted-packs`);
  }

  mintedPacksPaginate(userId: number, page?: number, size?: number, search?: string, where?: object, order?: string) {
    let query = new HttpParams();
    query = page ? query.append('page', page) : query;
    query = size ? query.append('size', size) : query;
    query = search ? query.append('search', search) : query;
    query = where ? query.append('where', JSON.stringify(where)) : query;

    return this.httpClient.get<PaginatedResponse<MintedPack[]>>(`${this.url}/${userId}/minted-packs`, { params: query });
  }

  pendingBids(userId: number) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const params = { 'auction.is_finished': false };
    let query = new HttpParams();
    query = query.append('size', 50);
    return this.httpClient.get<PaginatedResponse<PendingBid[]>>(`${this.url}/${userId}/bids`, {params: query});
  }

  pendingBidsOrder(userId: number) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    let params = new HttpParams();
    params = params.append('order', 'id DESC');    
    return this.httpClient.get<PaginatedResponse<PendingBid[]>>(`${this.url}/${userId}/bids`, {params});
  }

  pastBids(userId: number) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const params = { 'auction.is_finished': true };
    return this.httpClient.get<PaginatedResponse<PendingBid[]>>(`${this.url}/${userId}/bids`, {params});
  }

  info(userId: number) {
    return this.httpClient.get<BaseResponse<UserDetail>>(`${this.url}/${userId}/detail`);
  }

  saveInfo(userId: number, userDetail: UserDetail) {
    if (userDetail && userDetail.address === null) {
      delete userDetail.address;
    }
    if (userDetail && userDetail.number === null) {
      delete userDetail.number;
    }
    if (userDetail && userDetail.floor === null) {
      delete userDetail.floor;
    }
    if (userDetail && userDetail.apartment === null) {
      delete userDetail.apartment;
    }
    if (userDetail && userDetail.zipCode === null) {
      delete userDetail.zipCode;
    }
    if (userDetail && userDetail.city === null) {
      delete userDetail.city;
    }
    return this.httpClient.patch<BaseResponse<UserDetail>>(`${this.url}/${userId}/detail`, userDetail);
  }

  resetCredentials(userId: number, payload: any) {
    return this.httpClient.post<BaseResponse<UserDetail>>(`${this.url}/${userId}/reset-password`, payload);
  }
}
