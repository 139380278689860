<app-layout-modal (closed)="closeModal()">
  <div class="modal-content">
    <ion-grid *ngIf="!success && !loading" class="flex flex-col flex-between h-full">
      <ion-row class="ion-justify-content-center ion-padding">
        <ion-text class="bold problem-title">{{'result_modal.problem' | transloco}}</ion-text>
      </ion-row>

      <ion-row class="ion-justify-content-center ion-align-items-center ion-padding flex flex-col">
        <ion-text class="ion-margin-vertical problem-text">
          {{'result_modal.problem_card' | transloco}}
        </ion-text>

        <ion-text class="ion-margin-vertical problem-text max-w">
          {{'result_modal.problem_try' | transloco}}
        </ion-text>
      </ion-row>

      <ion-row class="w-full ion-padding">
        <ion-col>
          <ion-button (click)="closeModal()" expand="block" class="hover-to-light" fill="outline">
            <ion-text class="bold finish">{{'shared.cancel' | transloco}}</ion-text>
          </ion-button>
        </ion-col>
        <ion-col>
          <ion-button expand="block" color="light">
            <ion-text class="bold finish">{{'result_modal.enter_new_card' | transloco}}</ion-text>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>

    <ion-grid *ngIf="success && !loading" class="flex flex-col flex-between h-full">
      <ion-row class="ion-justify-content-center ion-padding">
        <ion-text class="bold problem-title">{{'result_modal.order_approved' | transloco}}</ion-text>
      </ion-row>

      <ion-row class="ion-justify-content-center ion-margin">
        <ion-icon size="large" color="light" name="checkmark-outline"></ion-icon>
      </ion-row>

      <ion-row class="ion-justify-content-center ion-padding">
        <ion-text class="ion-margin-vertical problem-text">
          {{'result_modal.sending_email' | transloco}}
        </ion-text>
      </ion-row>

      <ion-row class="w-full ion-padding">
        <ion-col>
          <ion-button expand="block" color="light" (click)="forwardToDefaultPage()">
            <ion-text class="bold  finish">{{'shared.finish' | transloco}}</ion-text>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>

    <ion-grid class="flex flex-col flex-between h-full" *ngIf="loading">
      <ion-row class="ion-justify-content-center ion-padding">
        <ion-text class="bold problem-title">{{'result_modal.processing_order' | transloco}}</ion-text>
      </ion-row>

      <ion-row class="ion-justify-content-center ion-margin">
        <ion-spinner class="spinner" color="light" name="crescent"></ion-spinner>
      </ion-row>

      <ion-row class="ion-justify-content-center ion-padding">
        <ion-text class="ion-margin-vertical problem-text max-w">
          {{'result_modal.wait' | transloco}}
        </ion-text>
      </ion-row>
    </ion-grid>

  </div>

</app-layout-modal>
