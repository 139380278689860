<app-layout-modal (closed)="cancelModal()">
  <section class="modal-content-wrapper">
    <div>
      <input type="file" #uploader hidden>
      <h4>Upload your avatar</h4>
    </div>
    <ng-container *ngIf="data.file === null; else imagePreview">
      <div class="art-container">
        <ngx-file-drop class="dropzone" dropZoneClassName="upload-dropzone-section" *ngIf="base64File === null"
          (onFileDrop)="dropped($event)">
          <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
            <div (click)="openFileSelector()"
              class="img-content ion-justify-content-center w-full ion-padding pointer ion-items-centered">
              <div class="art-container">
                <div class="image">
                  <app-temp-avatar class="image__img" size="big" [user]="user"></app-temp-avatar>
                  <div class="image__overlay image__overlay--primary">
                    <div class="image__title">Change avatar</div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </ngx-file-drop>
      </div>
    </ng-container>
    <ng-template #imagePreview>
      <ngx-file-drop class="dropzone" dropZoneClassName="upload-dropzone-section" *ngIf="base64File === null"
        (onFileDrop)="dropped($event)">
        <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
          <div (click)="openFileSelector()"
            class="img-content ion-justify-content-center w-full ion-padding pointer ion-items-centered">
            <div class="art-container">
              <div class="image">
                <img class="image__img" [src]="data.file?.url" [alt]="data.file?.objectKey">
                <div class="image__overlay image__overlay--primary">
                  <div class="image__title">Change avatar</div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ngx-file-drop>
    </ng-template>
    <ion-row>
      <ion-col class="text-center">
        <ion-button color="light" fill="outline" expand="block" (click)="cancelModal()">Cancel</ion-button>
      </ion-col>
      <ion-col class="text-center">
        <ion-button color="light" fill="outline" expand="block" (click)="closeModal()">Save</ion-button>
      </ion-col>
    </ion-row>
  </section>
</app-layout-modal>