/* eslint-disable @typescript-eslint/naming-convention */
import {Product} from './product';
import {Media} from './media';
import {Auction} from './auction';

export interface Pack {
  id: number;
  name: string;
  member_points: string;
  description: string;
  shortDescription?: string;
  thumbnail: Media;
  stock: number;
  icon: string;
  starts: string;
  ends: string;
  upcoming: boolean;
  products?: Product[];
  auction?: Auction;
  available?: number;

  created_at?: string;
  isDeleted?: false;
  status?: string;
  offering_method?: string;
  release_date?: string;
  price?: number;
  finalPrice?: number;
  currency?: string;
  id_onchain?: number;
  is_draft?: boolean;
}

export interface PackResponse {
  pack: Pack;
}
