import { Component, OnInit } from '@angular/core';
import { LanguageService } from './services/language.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(private readonly languageService: LanguageService) {
  }

  get environment() {
    return environment.env;
  }

  ngOnInit(): void {
    this.languageService.onInit();
  }
}
