<ion-grid class="ion-no-padding">
  <ion-row class="menu-wrapper">
    <ion-col class="pointer" routerLink="/fan/auction" sizeXs="10" sizeMd="10" sizeLg="2">
       <ion-img src="assets/images/logo-dillom.png"></ion-img>
      <!-- /* Se modifico para dillom */ -->
      <!-- <h3>DILLOM.RIP</h3> -->
    </ion-col>
    <ion-col sizeXs="2" sizeMd="2" sizeLg="0" class="menu-icon">
      <ion-button fill="clear" (click)="toggleMenu()">
        <ion-icon size="large" name="menu-outline"></ion-icon>
      </ion-button>
    </ion-col>
    <ion-col sizeLg="10">
      <ion-list class="menu-list menu-list-desktop">
        <!-- <ion-item lines="none" color="transparent" *transloco="let t; read:'language'">
          <ion-label class="language-label ion-justify-content-center ion-align-items-center menu-list-select">
            {{t(languageService.currentLanguage())}}
            <ion-icon name="chevron-down-outline"></ion-icon>
          </ion-label>
          <ion-select interface="popover" [value]="languageService.currentLanguage()" (ionChange)="setLanguage($event)">
            <ion-select-option value="en">{{t('en')}}</ion-select-option>
            <ion-select-option value="es">{{t('es')}}</ion-select-option>
          </ion-select>
        </ion-item> -->
        <ion-item *ngFor="let nav of navigation" lines="none">
          <a [routerLink]="nav.link" routerLinkActive="active">
            <h4>{{nav.name}}</h4>
          </a>
        </ion-item>        
        <ion-item lines="none" style="cursor: pointer;">
          <a (click)="openUserProfileModal($event, 'Dashboard')" routerLinkActive="active">
            <h4>Mi porfolio</h4>
          </a>
        </ion-item>
        <ion-item *ngIf="user" class="pointer" lines="none">
          <a href="#" (click)="openUserProfileModal($event, 'Dashboard')" class="avatar-wrapper">
            <ng-container *ngIf="user.detail.avatar; else noAvatar">
              <ion-avatar><img [src]="user.detail.avatar?.url" alt=""></ion-avatar>
            </ng-container>
            <ng-template #noAvatar>
              <app-temp-avatar size="normal" [user]="user"></app-temp-avatar>
            </ng-template>
            <h4>{{firstNamePart}}</h4>
          </a>
        </ion-item>        
        <ng-container *ngIf="!!!user">
          <ion-item routerLinkActive="active">
            <a [routerLink]="'/user/sign-up'" routerLinkActive="active">
              <h4 class="pointer">{{'signup.sign_up' | transloco}}</h4>
            </a>
          </ion-item>
          <ion-item>
            <ion-button color="light" fill="outline" size="large" style="font-size: 22px !important;"
                        (click)="goToLogin()">
              {{'login.button' | transloco}}
            </ion-button>
          </ion-item>
        </ng-container>
      </ion-list>
    </ion-col>
  </ion-row>
</ion-grid>

<div class="overlay" *ngIf="isOpen">
  <ion-button fill="clear" (click)="toggleMenu()" class="closebtn close-button">
    <!-- <ion-icon size="large" name="close-outline"></ion-icon> -->
    X
  </ion-button>
  <div class="overlay-content">
    <ion-grid>
      <ion-row>
        <ion-col size="12">
          <ion-list class="menu-list">
            <ng-container *ngIf="!!!user">
              <ion-item class="ion-margin-top" lines="none" color="transparent">
                <ion-button color="light" size="large" (click)="goToLogin()">{{'login.button' |
                  transloco}}</ion-button>
              </ion-item>

              <ion-item class="ion-margin-top" lines="none" color="transparent">
                <a href="#" (click)="goToSignUp($event)">
                  <h4 class="pointer">{{'signup.sign_up' | transloco}}</h4>
                </a>
              </ion-item>
            </ng-container>
            <!-- <ion-item class="ion-margin-top ion-margin-start" lines="none" color="transparent"
              *transloco="let t; read:'language'">
              <ion-label class="ion-margin-start">
                {{t(languageService.currentLanguage())}}
                <ion-icon name="chevron-down-outline"></ion-icon>
              </ion-label>
              <ion-select interface="popover" [value]="languageService.currentLanguage()"
                (ionChange)="setLanguage($event)">
                <ion-select-option value="en">{{t('en')}}</ion-select-option>
                <ion-select-option value="es">{{t('es')}}</ion-select-option>
              </ion-select>
            </ion-item> -->

            <ion-item *ngFor="let nav of navigation" lines="none">
              <a [routerLink]="nav.link" (click)="toggleMenu()" routerLinkActive="active">
                <h4>{{nav.name}}</h4>
              </a>
            </ion-item>            

            <ion-item lines="none" *ngIf="user" (click)="openUserProfileModal($event, 'Dashboard')">
              <ng-container *ngIf="user.detail.avatar; else noAvatar">
                <ion-avatar><img [src]="user.detail.avatar?.url" alt="profile img"></ion-avatar>
              </ng-container>
              <ng-template #noAvatar>
                <app-temp-avatar size="normal" [user]="user"></app-temp-avatar>
              </ng-template>
            </ion-item>
          </ion-list>
          <ion-list class="menu-list" style="margin-top: 15px;border-top: gray solid 1px;" *ngIf="user">
            <ion-item class="ion-margin-top" lines="none" color="transparent" style="margin-top: 0px;">
              <a href="#" (click)="openUserProfileModal($event, 'Dashboard')">
                <h4 class="pointer">Mi porfolio</h4>
              </a>
            </ion-item>
          </ion-list>
          <ion-list class="menu-list" *ngIf="user">
            <ion-item class="ion-margin-top" lines="none" color="transparent" style="margin-top: 0px;">
              <a href="#" (click)="openUserProfileModal($event, 'My account')">
                <h4 class="pointer">Mi perfil</h4>
              </a>
            </ion-item>
          </ion-list>
          <ion-list class="menu-list" *ngIf="user">
            <ion-item class="ion-margin-top" lines="none" color="transparent" style="margin-top: 0px;">
              <a href="#" (click)="logout()">
                <h4 class="pointer">Cerrar Sesión</h4>
              </a>
            </ion-item>
          </ion-list>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</div>
