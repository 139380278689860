<app-layout-modal (closed)="closeModal()">
  <div class="modal-content">
    <h2 class="title">{{'result_modal.order_approved' | transloco}}</h2>
  
    <section class="icon">
      <ion-icon color="light" name="checkmark-outline"></ion-icon>
    </section>
  
    <div class="info">
      <label>{{'result_modal.sending_email' | transloco}}</label>
    </div>
  
    <section class="actions">
      <ion-button color="light" expand="block" (click)="closeModal()">{{'shared.finish' | transloco}}</ion-button>
    </section>
  </div>
</app-layout-modal>