import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Pack, PackResponse} from '../shared/interface/pack';
import {Observable} from 'rxjs';
import {BaseResponse} from '../shared/interface/response';

@Injectable({
  providedIn: 'root'
})
export class PackService extends BaseService<BaseResponse<PackResponse>> {

  constructor(private httpClient: HttpClient) {
    super(httpClient);
    this.url = `${environment.apiServer}/packs`;
  }

  getPacks(): Observable<BaseResponse<any>> {
    return this.httpClient.get<any>(`${this.url}/?order=-created_at`);
  }
}
