import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PageActionsService {
  login: Subject<any> = new Subject<any>();
  loginSuccess: Subject<any> = new Subject<any>();
  showProfile: Subject<boolean> = new Subject<boolean>();

  constructor() {
  }

  openLogin() {
    this.login.next(null);
  }

  loginSuccessful() {
    this.loginSuccess.next(null);
  }
}
