<ion-grid>
  <!-- <ion-row class="ion-margin-vertical">
    <ion-item class="item-input w-full">
      <ion-input placeholder="{{'profile_modal.profile.search'  | transloco}}"></ion-input>
      <ion-icon name="search-outline" slot="end" color="light"></ion-icon>
    </ion-item>
  </ion-row> -->

  <form>
    <ion-accordion-group [value]="['profile','shipping', 'password']" multiple="true">
      <ion-accordion value="profile">
        <ion-item slot="header">
          <ion-label>{{'profile_modal.profile.profile.title' | transloco}}</ion-label>
        </ion-item>
        <div slot="content" [formGroup]="userForm">
          <ion-row class="ion-padding">
            <ion-col>
              <ion-label>{{'profile_modal.profile.profile.email' | transloco}}</ion-label>
              <ion-item class="item-input dark mt-10" fill="outline">
                <ion-input [disabled]="true" formControlName="email"></ion-input>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row class="ion-padding">
            <ion-col class="ion-margin-end" sizeXs="12" sizeSm="6">
              <ion-label>{{'profile_modal.profile.profile.name' | transloco}}</ion-label>
              <ion-item class="item-input dark mt-10" fill="outline">
                <ion-input formControlName="firstName"></ion-input>
              </ion-item>
            </ion-col>
            <ion-col>
              <ion-label>{{'profile_modal.profile.profile.last_name' | transloco}}</ion-label>
              <ion-item class="item-input dark mt-10" fill="outline">
                <ion-input formControlName="lastName"></ion-input>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row class="ion-padding">
            <ion-col class="ion-margin-end" sizeXs="12" sizeSm="6">
              <ion-label>{{'profile_modal.profile.profile.date_birth' | transloco}}</ion-label>
              <ion-item class="item-input dark mt-10" fill="outline">
                <!-- Datetime in overlay -->
                <ion-icon color="light" slot="end" name="calendar-outline"></ion-icon>
                <ion-input class="ion-text-start"
                           id="date-start"
                           formControlName="birthday">
                </ion-input>
                <ion-popover trigger="date-start" size="cover">
                  <ng-template>
                    <ion-datetime presentation="date"></ion-datetime>
                  </ng-template>
                </ion-popover>
              </ion-item>
            </ion-col>
            <ion-col>
              <ion-label>{{'profile_modal.profile.profile.gender' | transloco}}</ion-label>
              <ion-item class="item-input dark mt-10" fill="outline">
                <ion-select class="w-full"
                            interface="popover"
                            formControlName="gender">
                  <ion-select-option [value]="1">{{'signup.male' | transloco}}</ion-select-option>
                  <ion-select-option [value]="2">{{'signup.female' | transloco}}</ion-select-option>
                  <ion-select-option [value]="3">{{'signup.other' | transloco}}</ion-select-option>
                </ion-select>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row class="ion-padding">
            <ion-col class="ion-margin-end" sizeXs="12" sizeSm="6">
              <ion-label>{{'profile_modal.profile.profile.country' | transloco}}</ion-label>
              <ion-item class="item-input dark mt-10" fill="outline">
                <ion-select class="w-full"
                            interface="popover"
                            formControlName="country"
                            [compareWith]="compareWithCode">
                  <ion-select-option *ngFor="let country of countries" [value]="country">
                    {{country.name}}
                  </ion-select-option>
                </ion-select>
              </ion-item>
            </ion-col>
            <ion-col>
              <ion-label>{{'profile_modal.profile.profile.city' | transloco}}</ion-label>
              <ion-item class="item-input dark mt-10" fill="outline">
                <ion-select class="w-full"
                            interface="popover"
                            formControlName="city"
                            [compareWith]="compareWith">
                  <ion-select-option *ngFor="let city of cities" [value]="city">
                    {{city.name}}
                  </ion-select-option>
                </ion-select>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row class="ion-padding">
            <ion-col class="ion-margin-end" sizeXs="12" sizeSm="6">
              <ion-label>DNI</ion-label>
              <ion-item class="item-input dark mt-10" fill="outline">
                <ion-input formControlName="dni"></ion-input>
              </ion-item>
            </ion-col>
            <ion-col>
              <ion-label>{{'profile_modal.profile.profile.phone' | transloco}}</ion-label>
              <ion-item class="item-input dark mt-10" fill="outline">
                <ion-input formControlName="phoneNumber"></ion-input>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row class="ion-padding">
            <ion-col>
              <ion-button (click)="saveUserInfo()"
                          color="light" fill="solid" size="large">
                {{'profile_modal.profile.save' | transloco}}
              </ion-button>
            </ion-col>
          </ion-row>
        </div>
      </ion-accordion>
      <ion-accordion value="shipping">
        <ion-item slot="header">
          <ion-label>{{'profile_modal.profile.shipping.title' | transloco}}</ion-label>
        </ion-item>
        <div slot="content" [formGroup]="userDetailForm">
          <ion-row class="ion-padding">
            <ion-col>
              <ion-label>{{'profile_modal.profile.shipping.address' | transloco}}</ion-label>
              <ion-item class="item-input dark mt-10" fill="outline">
                <ion-input formControlName="address"></ion-input>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row class="ion-padding">
            <ion-col class="ion-margin-end" sizeXs="12" sizeSm="6">
              <ion-label>{{'profile_modal.profile.shipping.number' | transloco}}</ion-label>
              <ion-item class="item-input dark mt-10" fill="outline">
                <ion-input formControlName="number"></ion-input>
              </ion-item>
            </ion-col>
            <ion-col>
              <ion-label>{{'profile_modal.profile.shipping.floor' | transloco}}</ion-label>
              <ion-item class="item-input dark mt-10" fill="outline">
                <ion-input formControlName="floor"></ion-input>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row class="ion-padding">
            <ion-col class="ion-margin-end" sizeXs="12" sizeSm="6">
              <ion-label>{{'profile_modal.profile.shipping.apartment' | transloco}}</ion-label>
              <ion-item class="item-input dark mt-10" fill="outline">
                <ion-input formControlName="apartment"></ion-input>
              </ion-item>
            </ion-col>
            <ion-col>
              <ion-label>{{'profile_modal.profile.profile.zip' | transloco}}</ion-label>
              <ion-item class="item-input dark mt-10" fill="outline">
                <ion-input formControlName="zipCode"></ion-input>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row class="ion-padding">
            <ion-col class="ion-margin-end" sizeXs="12" sizeSm="6">
              <ion-label>{{'profile_modal.profile.profile.country' | transloco}}</ion-label>
              <ion-item class="item-input dark mt-10" fill="outline">
                <ion-select class="w-full"
                            interface="popover"
                            formControlName="country"
                            [compareWith]="compareWithCode">
                  <ion-select-option *ngFor="let country of countries" [value]="country">
                    {{country.name}}
                  </ion-select-option>
                </ion-select>
              </ion-item>
            </ion-col>
            <ion-col>
              <ion-label>{{'profile_modal.profile.profile.city' | transloco}}</ion-label>
              <ion-item class="item-input dark mt-10" fill="outline">
                <ion-select class="w-full"
                            interface="popover"
                            formControlName="city"
                            [compareWith]="compareWith">
                  <ion-select-option *ngFor="let city of cities" [value]="city">
                    {{city.name}}
                  </ion-select-option>
                </ion-select>
              </ion-item>
            </ion-col>
          </ion-row>
          <!--<ion-row class="ion-padding">
            <ion-col>
              <ion-text class="ion-margin-vertical" color="light">Other options</ion-text>
              <ion-item class="content">
                <ion-label color="light">SMS verification</ion-label>
                <ion-toggle mode="ios" slot="start" formControlName="smsVerification"></ion-toggle>
              </ion-item>
              <ion-item class="content">
                <ion-label color="light">Two step authentication</ion-label>
                <ion-toggle mode="ios" slot="start" formControlName="twoStepAuthentication"></ion-toggle>
              </ion-item>
              <ion-item class="content">
                <ion-label color="light">Receive private alerts on my email</ion-label>
                <ion-toggle mode="ios" slot="start" formControlName="receiveEmailAlerts"></ion-toggle>
              </ion-item>
              <ion-item class="content">
                <ion-label color="light">Secret mode</ion-label>
                <ion-toggle mode="ios" slot="start" formControlName="secretMode"></ion-toggle>
              </ion-item>
            </ion-col>
          </ion-row>-->
          <ion-row class="ion-padding">
            <ion-col>
              <ion-button (click)="saveUserDetailsInfo()"
                          color="light" fill="solid" size="large">
                  {{'profile_modal.profile.save' | transloco}}
              </ion-button>
            </ion-col>
          </ion-row>
        </div>
      </ion-accordion>
      <ion-accordion value="password">
        <ion-item slot="header">
          <ion-label>{{'profile_modal.profile.password.title' | transloco}}</ion-label>
        </ion-item>
        <div slot="content" [formGroup]="userCredentialsForm">
          <ion-row class="ion-padding">
            <ion-col class="ion-margin-end" sizeXs="12" sizeSm="6">
              <div>
                <ion-label>{{'profile_modal.profile.password.change' | transloco}}</ion-label>
                <ion-item class="item-input dark mt-10" fill="outline">
                  <ion-input type="password" formControlName="password"></ion-input>
                </ion-item>
              </div>
              <div>
                <ion-text class="flex flex-col">
                  <ion-text class="ion-margin-start mb-5 mt-5"
                            color="danger"
                            *ngIf="password.errors !== null && password.errors?.minlength && userCredentialsForm.dirty">
                    {{'signup.min_lenght' | transloco}}
                  </ion-text>
                  <ion-text class="ion-margin-start mb-5"
                            color="danger"
                            *ngIf="password.errors !== null && password.errors?.hasUpper && userCredentialsForm.dirty">
                            {{'signup.upper' | transloco}}
                  </ion-text>
                  <ion-text class="ion-margin-start mb-5"
                            color="danger"
                            *ngIf="password.errors !== null && password.errors?.hasLower && userCredentialsForm.dirty">
                            {{'signup.lower' | transloco}}
                  </ion-text>
                  <ion-text class="ion-margin-start"
                            color="danger"
                            *ngIf="password.errors !== null && password.errors?.hasNumber && userCredentialsForm.dirty">
                            {{'signup.number' | transloco}}
                  </ion-text>
                </ion-text>
              </div>
            </ion-col>
            <ion-col>
              <div>
                <ion-label>{{'profile_modal.profile.password.confirm' | transloco}}</ion-label>
                <ion-item class="item-input dark mt-10" fill="outline">
                  <ion-input type="password" formControlName="confirmPassword"></ion-input>
                </ion-item>
              </div>
              <div>
                <ion-text class="ion-margin-start mb-5"
                          color="danger"
                          *ngIf="userCredentialsForm.controls['confirmPassword'].errors?.matchPassword && userCredentialsForm.controls['confirmPassword'].dirty">
                          {{'signup.match_password' | transloco}}
                </ion-text>
              </div>
            </ion-col>
          </ion-row>

          <ion-row class="ion-padding">
            <ion-col>
              <ion-button (click)="savePassword()"
                          [disabled]="userCredentialsForm.invalid"
                          color="light" fill="solid" size="large">
                {{'profile_modal.profile.password.save' | transloco}}
              </ion-button>
            </ion-col>
          </ion-row>
        </div>
      </ion-accordion>
    </ion-accordion-group>
  </form>
</ion-grid>
