import { Component, Input } from '@angular/core';
import { PendingBid } from '../../../../shared/interface/user';

@Component({
  selector: 'app-mini-auction',
  templateUrl: './mini-auction.component.html',
  styleUrls: ['./mini-auction.component.scss'],
})
export class MiniAuctionComponent {
  @Input() pendingBid: PendingBid;

}
