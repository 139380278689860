import {Component, OnDestroy, OnInit} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {UtilsService} from 'src/app/services/utils.service';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-layout-wrapper',
  templateUrl: './layout-wrapper.component.html',
  styleUrls: ['./layout-wrapper.component.scss'],
})
export class LayoutWrapperComponent implements OnInit, OnDestroy {
  // TODO: define user interface
  user: any;
  public activeRoute: boolean;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private readonly router: Router,
    private utilService: UtilsService
  ) {
    this.user = localStorage.getItem('user') ? localStorage.getItem('user') : undefined;
  }

  ngOnInit() {
    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter((event) => event instanceof NavigationEnd),
        tap(
          ({ url }: NavigationEnd) => (this.activeRoute = this.hasGradient(url))
        )
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private hasGradient = (url: string) => url === '/user/sign-up';

}
