import {Injectable} from '@angular/core';
import {Signal} from '../shared/interface/signal';
import {AuthService} from './auth.service';
import {User} from '../shared/interface/user';
import {Subject} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SignalService {
  reference: number;
  direct: boolean;
  client: WebSocket;
  user: User;
  signalStatus: Subject<Signal> = new Subject<Signal>();

  constructor(private auth: AuthService) {
    this.user = this.auth.getUser();
  }

  open(referenceId: number, direct: boolean) {
    this.reference = referenceId;
    this.direct = direct;
    this.client = new WebSocket(environment.paymentWebSocket);
    this.client.onopen = () => this.onOpen();
    this.client.onclose = () => this.onClose();
    this.client.onerror = (error) => this.onError(error);
    this.client.onmessage = (event) => this.onMessage(event);
  }

  keepAlive() {
    this.sendIdentification();
  }

  private onOpen() {
    console.log('@==>', 'Connection opened successfully');
    setTimeout(() => {
      this.sendIdentification();
    }, 100);
  }

  private onClose() {
    console.log('@==>', 'Connection closed');
  }

  private onError(error) {
    console.log('@==>', 'Connection error', error);
  }

  private onMessage(eventSignal) {
    const message: Signal = JSON.parse(eventSignal.data);
    this.signalStatus.next(message);
    console.log('@==>', 'Message received: ', message);
  }

  private sendIdentification() {
    const identificationSignal: Signal = {
      user: {
        id: this.user.id
      },
      signal: {
        confirmation: null,
        // reference: this.reference,
        paymentReference: {
          id: this.reference,
          direct: this.direct
        },
        message: 'need payment confirmation',
      }
    };
    this.client.send(JSON.stringify(identificationSignal));
  }
}
