import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {RouterModule} from '@angular/router';
import {LayoutWrapperComponent} from './component/layout-wrapper/layout-wrapper.component';
import {FooterComponent} from './component/footer/footer.component';
import {CountdownComponent} from './component/countdown/countdown.component';
import {SeparatorComponent} from './component/separator/separator.component';
import {LayoutModalComponent} from './component/layout-modal/layout-modal.component';
import {TermsComponent} from './component/terms/terms.component';
import {HeaderComponent} from './component/header/header.component';
import {TranslocoModule} from '@ngneat/transloco';
import {TempAvatarComponent} from './component/temp-avatar/temp-avatar.component';
import {PageHeaderComponent} from './component/page-header/page-header.component';
import {SigningComponent} from './component/signing/signing.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxCaptchaModule} from 'ngx-captcha';
import { PaymentErrorModalComponent } from './payment-error-modal/payment-error-modal.component';
import { PaymentSuccessModalComponent } from './payment-success-modal/payment-success-modal.component';
import { ResultModalComponent } from './component/result-modal/result-modal.component';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';


@NgModule({
  declarations: [
    LayoutWrapperComponent,
    LayoutModalComponent,
    FooterComponent,
    HeaderComponent,
    CountdownComponent,
    SeparatorComponent,
    TermsComponent,
    TempAvatarComponent,
    PageHeaderComponent,
    SigningComponent,
    PaymentErrorModalComponent,
    PaymentSuccessModalComponent,
    ResultModalComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    TranslocoModule,
    FormsModule,
    ReactiveFormsModule,
    NgxQRCodeModule,
    NgxCaptchaModule
  ],
  exports: [
    TranslocoModule,
    LayoutWrapperComponent,
    LayoutModalComponent,
    FooterComponent,
    HeaderComponent,
    CountdownComponent,
    SeparatorComponent,
    TermsComponent,
    TempAvatarComponent,
    PageHeaderComponent,
    PaymentErrorModalComponent,
    PaymentSuccessModalComponent,
    NgxQRCodeModule,
    ResultModalComponent
  ]
})
export class SharedModule {
}
