import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-layout-modal',
  templateUrl: './layout-modal.component.html',
  styleUrls: ['./layout-modal.component.scss'],
})
export class LayoutModalComponent {
  @Output() closed: EventEmitter<any> = new EventEmitter<any>();
  @Output() backed: EventEmitter<any> = new EventEmitter<any>();
  @Input() border = false;
  @Input() scrollable = false;
  @Input() scrollableHeight: number;
  @Input() icon: 'close' | 'back' = 'close';

}
