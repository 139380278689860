import { Component } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';
import { SigningComponent } from '../signing/signing.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {

  currentYear: number = new Date().getFullYear();

  constructor(private utilService: UtilsService){

  }

  async goToLogin() {
    await this.utilService.showModal(SigningComponent, ['signing-modal', 'opacity-100', 'login-modal-size'], null, false);
  }

  isLoggedIn() {
    return localStorage.getItem('user');
  }

}
