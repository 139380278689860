<div class="modal-container">
  <section class="modal-header">
    <ion-button class="close-button" *ngIf="icon==='close'" (click)="closed.emit()">
      <!-- <ion-icon class="bold" size="large" name="close-outline"></ion-icon> -->
      X
    </ion-button>
    <ion-button *ngIf="icon==='back'" (click)="backed.emit()">
      <ion-icon class="bold" size="large" name="arrow-back-outline"></ion-icon>
    </ion-button>
  </section>
  <section class="modal-content"
           [ngClass]="{'border': border === true, 'scrollable': scrollable}"
           [ngStyle]="{'height': scrollable ? scrollableHeight + 'px' : ''}">
    <ng-content></ng-content>
  </section>
</div>
