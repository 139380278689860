import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {UtilsService} from '../../../services/utils.service';
import {UserProfileModalComponent} from '../../../pages/user/user-profile-modal/user-profile-modal.component';
import {LanguageService} from '../../../services/language.service';
import {AuthService} from '../../../services/auth.service';
import {User} from '../../interface/user';
import {SigningComponent} from '../signing/signing.component';
import {PageActionsService} from '../../../services/page-actions.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  user: User;
  isOpen = false;
  public userAvatar = 'assets/images/user/profile.svg';
  public navigation = [
    {
      name: 'Drops',
      link: '/fan/auction',
    },
    // /* Se modifico para dillom */
    // {
    //   name: 'Marketplace',
    //   link: '/fan/marketplace',
    // },
    {
      name: 'F.A.Q.',
      link: '/fan/faq',
    },
  ];
  unsubscribe: Subject<any> = new Subject<any>();

  constructor(private router: Router,
              private utilService: UtilsService,
              private authService: AuthService,
              public readonly languageService: LanguageService,
              private pageActionsService: PageActionsService,) {
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.user = this.authService.getUser();
      }
    });
  }

  get firstNamePart() {
    return this.user && this.user.firstName ? this.user.firstName.split(' ')[0] : '';
  }

  ngOnInit() {
    this.authService.userInfoUpdated
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(user => this.user = user);
    this.pageActionsService.login
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async _ => this.goToLogin());
    this.pageActionsService.loginSuccess
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.user = this.authService.getUser());
  }

  ngAfterViewInit(): void {
    this.pageActionsService.showProfile
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async () => {
        await this.openUserProfileModal();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  toggleMenu = () => (this.isOpen = !this.isOpen);

  public setLanguage(e) {
    this.languageService.setLanguage(e.detail.value);
  }

  async goToLogin() {
    await this.utilService.showModal(SigningComponent, ['signing-modal', 'opacity-100', 'login-modal-size'], null, false);
  }

  async openUserProfileModal(event?: any, pSelected?: string) {
    if (event) {
      event.preventDefault();
    }
    await this.utilService.showModal(UserProfileModalComponent, [
      'opacity-100',
      'wide-modal',
    ],{ 
      selected: pSelected,      
    }, false);
  }

  async goToSignUp(event: any) {
    event.preventDefault();
    await this.router.navigate(['user', 'sign-up']);
  }

  logout(){
    this.authService.logout();
    this.utilService.goPage('fan/auction');
    this.authService.userInfoUpdated.next(null);
  }
}
