<ion-grid>
  <ion-row class="ion-padding-vertical ion-padding-start ion-align-items-center">
    <h3 class="title">Your collection</h3>
  </ion-row>

  <!-- <ion-row class="ion-margin-vertical">
    <ion-item class="item-input w-full">
      <ion-input placeholder="Search for a pack"></ion-input>
      <ion-icon name="search-outline" slot="end" color="light"></ion-icon>
    </ion-item>
  </ion-row> -->

  <!-- packs -->
  <ion-row class="packs-wrapper">
    <app-mini-pack *ngFor="let item of packs" [pack]="item"></app-mini-pack>
  </ion-row>
  <ion-infinite-scroll threshold="100px" (ionInfinite)="loadData($event)">
    <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Loading more data…">
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
  
</ion-grid>