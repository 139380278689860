import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {environment} from '../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Auction, AuctionResponse} from '../shared/interface/auction';
import {Observable} from 'rxjs';
import {PaginatedResponse} from '../shared/interface/response';

@Injectable({
  providedIn: 'root'
})
export class AuctionService extends BaseService<AuctionResponse> {

  constructor(private httpClient: HttpClient) {
    super(httpClient);
    this.url = `${environment.apiServer}/auctions`;
  }

  getAuctionByType(type: string, page: number, size: number) {
    let query = new HttpParams();
    query = query.append('order', '-start_date');
    query = type ? query.append('list', type) : query;
    query = page ? query.append('page', page) : query;
    query = size ? query.append('size', size) : query;
    return this.httpClient.get<PaginatedResponse<Auction[]>>(this.url, {params: query});
  }

  getNextAuction(): Observable<AuctionResponse> {
    return this.httpClient.get<AuctionResponse>(`${this.url}/next-auction/`);
  }

  getAuctions(): Observable<any> {
    return this.httpClient.get<any>(`${this.url}/`);
  }

}
