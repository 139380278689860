export const environment = {
  production: true,
  env: 'production',
  apiServer:
    'https://3myvdgtxnh.execute-api.us-east-1.amazonaws.com/real-prod',
  publicKeyMercadopago: 'APP_USR-bc169ed1-365d-4282-8d25-8c46c015d213',
  googleClientId:
    '27179106000-mmmekj9obqmcplung05thkaj4ba9allg.apps.googleusercontent.com',
  paymentWebSocket:
    'wss://3myvdgtxnh.execute-api.us-east-1.amazonaws.com/real-prod',
  captchaKey: '6Lc2iMknAAAAAIBU268dZE8w1CD08qNzz1FUqGTe',
  captchaKeyV3: '6Lc2iMknAAAAAIBU268dZE8w1CD08qNzz1FUqGTe',
};
