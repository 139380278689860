import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {interval, Subscription} from 'rxjs';

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss'],
})
export class CountdownComponent implements OnInit, OnDestroy {
  @Output() finished: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() type = 'normal';
  @Input() showText = true;
  @Input() fontSize = '1.25rem';
  @Input() timeFontSize = '1.25rem';
  @Input() fontLabelSize = '1.25rem';
  @Input() redirectWhenFinished = false;
  @Input() background = '#1A1A1A';

  incomingTimestamp: string;

  auctionDate;
  timeDifference;
  secondsToDay;
  minutesToDay;
  hoursToDay;
  daysToDay;

  private subscription: Subscription;

  get timestamp() {
    return this.incomingTimestamp;
  }

  @Input()
  set timestamp(timestamp: string) {
    this.incomingTimestamp = timestamp;
    this.auctionDate = new Date(this.timestamp);
  }

  ngOnInit() {
    this.auctionDate = new Date(this.timestamp);
    this.subscription = interval(1000)
      .subscribe(_ => {
        this.getTimeDifference();
      });
  }

  getTimeDifference() {
    this.timeDifference = this.auctionDate.getTime() - new Date().getTime();
    this.allocateTimeUnits(this.timeDifference > 0 ? this.timeDifference : 0);
  }

  allocateTimeUnits(timeDifference) {
    this.secondsToDay = Math.floor((timeDifference) / (1000) % 60);
    this.minutesToDay = Math.floor((timeDifference) / (1000 * 60) % 60);
    this.hoursToDay = Math.floor((timeDifference) / (1000 * 60 * 60) % 24);
    this.daysToDay = Math.floor((timeDifference) / (1000 * 60 * 60 * 24));
    const total = this.secondsToDay + this.minutesToDay + this.hoursToDay + this.daysToDay;
    if (total === 0 && this.redirectWhenFinished) {
      this.finished.emit(true);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
