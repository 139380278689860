import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AvatarUploadMedia } from '../../../../shared/interface/media';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { UtilsService } from '../../../../services/utils.service';
import { ModalController } from '@ionic/angular';
import { AppConstants } from '../../../../app.constants';
import { User } from '../../../../shared/interface/user';
import { AuthService } from '../../../../services/auth.service';

@Component({
  selector: 'app-avatar-upload',
  templateUrl: './avatar-upload.component.html',
  styleUrls: ['./avatar-upload.component.scss'],
})
export class AvatarUploadComponent implements OnInit {
  @ViewChild('uploader', { static: true }) uploader: ElementRef<HTMLInputElement>;
  @Input() data: AvatarUploadMedia;
  base64File: string = null;
  original: any = null;
  file: File = null;
  files: NgxFileDropEntry[] = [];
  user: User = null;

  constructor(private utils: UtilsService,
    private modalController: ModalController,
    private authService: AuthService) {
  }

  ngOnInit() {
    this.user = this.authService.getUser();
    this.original = this.data.file;
  }

  async closeModal() {

    if (this.original) {
      // esta editando
      if (this.data.file.url) {
        this.utils.dismissModal({
          fileBase64: this.data.file.url,
          fileName: this.data.file.objectKey
        });
      } else {
        this.utils.dismissModal(null);
      }
    } else {
      if (this.data.file.url) {
        this.utils.dismissModal({
          fileBase64: this.data.file.url,
          fileName: this.data.file.objectKey
        });
      }
    }
  }

  async dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file(async (file: File) => {
          await this.pickupFile(file);
        });
      } else {
        await this.utils.showToast('Only files are allowed');
      }
    }
  }

  async pickupFile(file: any) {
    const ext = file.name.split('.').pop().toLowerCase();

    if (!this.data.allowedFiles.includes(ext)) {
      await this.utils.showToast('File extension not allowed');
      return;
    }
    this.file = file;
    this.data.file = {
      objectKey: file.name,
      url: await this.utils.file2Base64(file)
    };
  }

  async popFileChooser() {
    this.uploader.nativeElement.click();
    this.uploader.nativeElement.onchange = async (ev) => {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      const file = ev.target['files'][0];
      await this.pickupFile(file);
    };
  }

  async cancelModal() {
    await this.modalController.dismiss();
  }

}
