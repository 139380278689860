import { Component, OnDestroy, OnInit } from '@angular/core';
import { Signal } from '../../interface/signal';
import { SignalService } from '../../../services/signal.service';
import { BidStatus } from '../../enums/bid-status';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-result-modal',
  templateUrl: './result-modal.component.html',
  styleUrls: ['./result-modal.component.scss'],
})
export class ResultModalComponent implements OnInit, OnDestroy {
  id: number;
  direct: boolean;
  purchaseId: number | null = null;
  bidId: number | null = null;
  loading = true;
  success = true;
  interval = null;
  unsubscribe: Subject<any> = new Subject<any>();

  constructor(private router: Router,
              private signal: SignalService,
              private modalController: ModalController) {
  }

  ngOnInit() {
    this.openConnection();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  openConnection() {
    this.signal.open(this.purchaseId ?? this.bidId, this.direct);
    this.signal.signalStatus.subscribe((message: Signal) => {
      console.log('@==>', 'socket says:', message);
      if (this.direct) {
        this.loading = message.signal.confirmation !== BidStatus.CONFIRMED;
      } else {
        this.loading = message.signal.confirmation !== BidStatus.REQUIRES_CAPTURE;
      }
    });
  }

  async closeModal() {
    await this.modalController.dismiss({});
    if (this.purchaseId) {
      await this.router.navigate(['fan', 'marketplace']);
    } else {
      await this.router.navigate(['fan', 'auction', this.id]);
    }
    window.location.reload();
  }

  async forwardToDefaultPage() {
    await this.router.navigate(['/fan/marketplace']);
    await this.modalController.dismiss({});
  }

}
