import {Faq} from './shared/interface/faq';

export class AppConstants {
  public static faqs: Faq[] = [
    {
      question: 'marketplace.question1',
      answer: 'marketplace.answer1'
    },
    {
      question: 'marketplace.question2',
      answer: 'marketplace.answer2'
    },
    {
      question: 'marketplace.question3',
      answer: 'marketplace.answer3'
    }
  ];

  public static keys: any = {
    user: 'user',
    token: 'idToken',
    accessToken: 'accessToken',
    refreshToken: 'refreshToken',
    lang: 'lang',
  };

  public static headers: any = {
    authorization: 'Authorization',
    accessToken: 'Access-Token',
  };

  public static misc: any = {
    file: {
      urlCheck: 'http',
      urlSafeCheck: 'https'
    }
  };

  public static formats: any = {
    date: 'YYYY-MM-DD'
  };

  public static modal: any = {
    sizes: {
      small: ['small-modal'],
      normal: ['normal-modal'],
      normalDefault: ['opacity-100', 'normal-modal'],
      wide: ['wide-modal'],
      uploadFile: ['upload-modal'],
      detailed: ['detailed-modal']
    }
  };

  public static files: any = {
    video: {
      title: 'Drop the video file.',
      subtitle: 'Please only import image type files.  Max 2000 MB',
      allowedFiles: ['jpg', 'tiff', 'gif', 'png']
    },
    metadata: {
      title: 'Drop the template here with the metadata.',
      subtitle: 'If you don\'t have the template please download right here.',
      allowedFiles: ['xlx', 'xlsx', 'txt']
    },
    thumbnail: {
      title: 'Drop the thumbnail image file.',
      subtitle: 'Please only import image type files.  Max 2000 MB',
      allowedFiles: ['jpg', 'tiff', 'png', 'jpeg']
    }
  };
}
