import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-payment-success-modal',
  templateUrl: './payment-success-modal.component.html',
  styleUrls: ['./payment-success-modal.component.scss'],
})
export class PaymentSuccessModalComponent {
  id: number;

  constructor(private modalController: ModalController,
    private router: Router) {
  }

  async closeModal() {
    await this.modalController.dismiss({});
    await this.router.navigate(['fan', 'auction', this.id]);
    window.location.reload();
  }

}
