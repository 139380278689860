export enum BidStatus {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  PENDING = 0,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  CONFIRMED = 1,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ERROR = 2,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  REQUIRES_CAPTURE = 3,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  CANCELED = 4,
}
