import {Component, Input, OnInit} from '@angular/core';
import {User, UserDetail} from '../../../../shared/interface/user';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {City, Country} from '../../../../shared/interface/country';
import {CountryService} from '../../../../services/country.service';
import {UserService} from '../../../../services/user.service';
import to from 'await-to-js';
import {UtilsService} from '../../../../services/utils.service';
import {AuthService} from '../../../../services/auth.service';
import {MatchPasswordValidation} from '../../../../shared/validators/matchPassword.validator';
import {CustomValidator} from '../../../../shared/validators/custom.validator';

@Component({
  selector: 'app-profile-account',
  templateUrl: './profile-account.component.html',
  styleUrls: ['./profile-account.component.scss'],
})
export class ProfileAccountComponent implements OnInit {
  countries: Country[] = [];
  cities: City[] = [];
  user: User = null;
  userForm: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl(),
    firstName: new UntypedFormControl(),
    lastName: new UntypedFormControl(),
    birthday: new UntypedFormControl(),
    gender: new UntypedFormControl(),
    city: new UntypedFormControl(),
    country: new UntypedFormControl(),
    dni: new UntypedFormControl(),
    phoneNumber: new UntypedFormControl(),
  });
  userDetailForm: UntypedFormGroup = new UntypedFormGroup({
    address: new UntypedFormControl(),
    // eslint-disable-next-line id-blacklist
    number: new UntypedFormControl(),
    floor: new UntypedFormControl(),
    apartment: new UntypedFormControl(),
    zipCode: new UntypedFormControl(),
    // city: new UntypedFormControl(),
    // state: new UntypedFormControl(),
    city: new UntypedFormControl(),
    country: new UntypedFormControl(),
    smsVerification: new UntypedFormControl(),
    twoStepAuthentication: new UntypedFormControl(),
    receiveEmailAlerts: new UntypedFormControl(),
    secretMode: new UntypedFormControl(),
  });
  userCredentialsForm: UntypedFormGroup = new UntypedFormGroup({
    password: new UntypedFormControl(null, [
      Validators.required,
      Validators.minLength(8),
      CustomValidator.hasUpper,
      CustomValidator.hasLower,
      CustomValidator.hasNumber,
    ]),
    confirmPassword: new UntypedFormControl(null, [Validators.required, Validators.minLength(8)]),
  },
  [MatchPasswordValidation.matchPassword]);
  userDetail: UserDetail = null;

  constructor(private countryService: CountryService,
              private authService: AuthService,
              private userService: UserService,
              private utilsService: UtilsService) {
  }

  get password() {
    return this.userCredentialsForm.controls.password as UntypedFormControl;
  }

  get country() {
    return this.userForm.controls.country as UntypedFormControl;
  }

  get city() {
    return this.userForm.controls.city as UntypedFormControl;
  }

  get shippingCountry() {
    return this.userDetailForm.controls.country as UntypedFormControl;
  }

  get shippingCity() {
    return this.userDetailForm.controls.city as UntypedFormControl;
  }

  /*get userDetail(): UserDetail {
    return this.userDetailTemp;
  }

  @Input() set userDetail(value: UserDetail) {
    this.userDetailTemp = value;
    this.loadUserDetail();
  }*/

  async ngOnInit() {
    console.log('@==>', 11111111);
    await this.getCountries();
    this.user = this.authService.getUser();
    this.city.valueChanges.subscribe(value => console.log('cities changed: ', value));
    this.country.valueChanges.subscribe(value => {
      if (value) {
        this.city.setValue(null);
        this.getCities(value.code);
      }
    });
    this.shippingCity.valueChanges.subscribe(value => console.log('cities changed: ', value));
    this.shippingCountry.valueChanges.subscribe(value => {
      if (value) {
        this.shippingCity.setValue(null);
        this.getCities(value.code);
      }
    });
    this.loadUser();
    const [errorInfo, responseInfo] = await to(this.userService.info(this.user.id).toPromise());
    if (responseInfo) {
      this.userDetail = responseInfo.data;
    }
    this.loadUserDetail();
  }

  loadUser() {
    this.userForm.patchValue({
      email: this.user.email,
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      birthday: this.user.birthday,
      gender: this.user.gender,
      city: this.user.city,
      country: this.user.city?.country,
      dni: this.user.dni,
      phoneNumber: this.user.phoneNumber,
    });
    if (this.user.city) {
      const index = this.getCityIndexOrAddIfDoesntExist(this.user.city);
      this.city.setValue(this.cities[index]);
    }
  }

  loadUserDetail() {
    this.userDetailForm.patchValue({
      address: this.userDetail.address,
      // eslint-disable-next-line id-blacklist
      number: this.userDetail.number,
      floor: this.userDetail.floor,
      apartment: this.userDetail.apartment,
      zipCode: this.userDetail.zipCode,
      // city: this.userDetail.city,
      // state: this.userDetail.state,
      city: this.userDetail.city,
      country: this.userDetail.city?.country,
      smsVerification: this.userDetail.smsVerification,
      twoStepAuthentication: this.userDetail.twoStepAuthentication,
      receiveEmailAlerts: this.userDetail.receiveEmailAlerts,
      secretMode: this.userDetail.secretMode,
    });
    if (this.userDetail.city) {
      const index = this.getCityIndexOrAddIfDoesntExist(this.userDetail.city);
      this.shippingCity.setValue(this.cities[index]);
    }
  }

  async getCountries() {
    await this.utilsService.showLoading(null, 10 * 1000);
    const [countriesError, countriesResponse] = await to(this.countryService.getCountries().toPromise());
    await this.utilsService.dismissLoading();
    if (countriesResponse) {
      this.countries = countriesResponse.data.countries;
    }
  }

  async getCities(countryCode: string) {
    const [countriesError, citiesResponse] = await to(this.countryService.getCitiesByCountry(countryCode).toPromise());
    if (citiesResponse) {
      this.cities = citiesResponse.data.data;
    }
  }

  async saveUserInfo() {
    const payload = this.userForm.getRawValue();
    delete payload.country;
    if (payload.city) {
      payload.city = payload.city.id;
    }
    await this.utilsService.showLoading(null, 10 * 1000);
    const [error, response] = await to(this.userService.updateById(this.user.id, payload).toPromise());
    await this.utilsService.dismissLoading();
    if (response && response.data.data) {
      this.authService.updateInfo(response.data.data);
      await this.utilsService.showAlert('Carga exitosa','Tu información ha sido actualizada', ['Ok']);
    }
  }

  async saveUserDetailsInfo() {
    const payload = this.userDetailForm.getRawValue();
    delete payload.country;
    if (payload.city) {
      payload.city = payload.city.id;
    }
    payload.smsVerification = payload.smsVerification === null ? false : payload.smsVerification;
    payload.twoStepAuthentication = payload.twoStepAuthentication === null ? false : payload.twoStepAuthentication;
    payload.receiveEmailAlerts = payload.receiveEmailAlerts === null ? false : payload.receiveEmailAlerts;
    payload.secretMode = payload.secretMode === null ? false : payload.secretMode;
    await this.utilsService.showLoading(null, 10 * 1000);
    const [error, response] = await to(this.userService.saveInfo(this.user.id, payload).toPromise());
    await this.utilsService.dismissLoading();
    if (response) {
      await this.utilsService.showAlert('Carga exitosa','Tu información ha sido actualizada', ['Ok']);
    }
  }

  async savePassword() {
    const payload = this.userCredentialsForm.getRawValue();
    await this.utilsService.showLoading(null, 10 * 1000);
    const [error, response] = await to(this.userService.resetCredentials(this.user.id, payload).toPromise());
    await this.utilsService.dismissLoading();
    if (response) {
      await this.utilsService.showToast('Your information has been updated!');
    }
  }

  getCityIndexOrAddIfDoesntExist(city: City): number {
    let index = null;
    const check = this.cities.findIndex(item => item.id === city.id);
    if (check < 0) {
      this.cities.push(city);
      index = this.cities.findIndex(item => item.id === city.id);
    }
    return index !== null ? index : check;
  }

  compareWith(o1, o2) {
    return o1 && o2 ? o1.id === o2.id : o1 === o2;
  }

  compareWithCode(o1, o2) {
    return o1 && o2 ? o1.code === o2.code : o1 === o2;
  }

}
