import {Component, Input, OnInit} from '@angular/core';
import {Pack} from '../../../../shared/interface/pack';
import {UtilsService} from '../../../../services/utils.service';
import {NormalPackComponent} from '../normal-pack/normal-pack.component';
import {AppConstants} from '../../../../app.constants';
import to from 'await-to-js';
import { PackService } from 'src/app/services/pack.service';

@Component({
  selector: 'app-mini-pack',
  templateUrl: './mini-pack.component.html',
  styleUrls: ['./mini-pack.component.scss'],
})
export class MiniPackComponent implements OnInit {
  @Input() pack: Pack;

  constructor(private utilsService: UtilsService, private packService: PackService, private utilService: UtilsService) {
  }

  ngOnInit() {
  }

  async showDetails() {
    await this.utilService.showLoading(null, 10 * 1000);
    const [_, response] = await to(this.packService.getOneById(this.pack.id).toPromise());
    this.pack = response.data.pack;
    await this.utilService.dismissLoading();
    await this.utilsService.showModal(
      NormalPackComponent,
      AppConstants.modal.sizes.wide,
      {pack: this.pack},
      false
    );
  }

}
