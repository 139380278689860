import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Paginated} from '../shared/interface/common';

export class BaseService<T> {
  url: string;

  constructor(private baseHttpClient: HttpClient) {
  }

  save(payload: T): Observable<T> {
    return this.baseHttpClient.post<T>(`${this.url}`, payload);
  }

  updateById(id: number, payload: T): Observable<T> {
    return this.baseHttpClient.patch<T>(`${this.url}/${id}`, payload);
  }

  deleteById(id: number): Observable<T> {
    return this.baseHttpClient.delete<T>(`${this.url}/${id}`);
  }

  getOneById(id: number): Observable<T> {
    return this.baseHttpClient.get<T>(`${this.url}/${id}`);
  }

  getAllPaginatedByPage(page: number, size: number): Observable<Paginated<T>> {
    let query = new HttpParams();
    query = page ? query.append('page', page) : query;
    query = size ? query.append('size', size) : query;
    return this.baseHttpClient.get<Paginated<T>>(`${this.url}`, {params: query});
  }

  getAllByPage(page: number, size: number): Observable<T[]> {
    let query = new HttpParams();
    query = page ? query.append('page', page) : query;
    query = size ? query.append('size', size) : query;
    return this.baseHttpClient.get<T[]>(`${this.url}`, {params: query});
  }
}
