<app-layout-modal (closed)="closeModal()">
  <ion-grid class="signing-wrapper ion-padding">
    <ion-row>
      <ion-col size="12">
        <h2>{{'login.title' | transloco}}</h2>
      </ion-col>
    </ion-row>
    <form [formGroup]="signInForm">
      <ion-row>
        <ion-col>
          <ion-item class="ion-no-margin ion-no-padding" lines="none">
            <ion-label position="stacked" class="label">{{'login.email' | transloco}}:</ion-label>
            <ion-input formControlName="email" autocomplete="off"></ion-input>
          </ion-item>
          <ion-row *ngIf="email.errors?.email">
            <div class="signup-column">
              <ion-item lines="none">
                <ion-text class="error-form">{{'login.must_be_valid_email' | transloco}}</ion-text>
              </ion-item>
            </div>
          </ion-row>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-item class="ion-no-margin ion-no-padding" lines="none">
            <ion-icon
              (click)="showPassword = !showPassword"
              color="light"
              class="pointer m-0 custom-show"
              [name]="showPassword ? 'eye-outline' : 'eye-off-outline'"></ion-icon>
            <ion-label position="stacked" class="label">{{'login.password' | transloco}}:</ion-label>
            <ion-input [type]="showPassword ? 'text' : 'password'" formControlName="password" autocomplete="off"></ion-input>
          </ion-item>
          <ion-row *ngIf="password.errors?.required && password.dirty">
            <div class="signup-column">
              <ion-item lines="none">
                <ion-text>
                  {{'login.password_required' | transloco}}
                </ion-text>
              </ion-item>
            </div>
          </ion-row>
        </ion-col>
      </ion-row>
      <ion-row class="signing-captcha">
        <ion-col class="ion-items-centered">
          <div class="captcha">
            <ngx-recaptcha2 [siteKey]="captchaKeyV2"
                            [useGlobalDomain]="true"
                            (success)="resolve($event)"></ngx-recaptcha2>
          </div>
        </ion-col>
      </ion-row>
      <ion-row class="signing-actions">
        <ion-col class="ion-align-self-center">
          <!-- <ion-button [disabled]="signInForm.invalid === true || captchaResolved === false"
                      (click)="login()"
                      class="login-btn" fill="outline" size="large">{{'login.button' | transloco}}</ion-button> -->
                <ion-button [disabled]="signInForm.invalid === true || captchaResolved === false"
                      (click)="login()"
                      class="login-btn" fill="outline" size="large">{{txtButton}}</ion-button>                      
                <ion-button 
                      (click)="closeModal()"
                      class="login-btn" fill="solid" size="large">Cancelar</ion-button>   
        </ion-col>
      </ion-row>
    </form>
    <ion-row>
      <ion-col class="ion-text-center">
        <p class="signup-disclaimer">
          {{'login.forgot_password' | transloco}} <a href="#" (click)="getAnewPassword($event)">{{'login.get_new_password' | transloco}}</a>.
        </p>
        <p class="signup-disclaimer">
          {{'login.not_member_yet' | transloco}} <a href="#" (click)="getAnewAccount($event)">{{'login.create_account' | transloco}}</a>.
        </p>
      </ion-col>
    </ion-row>
  </ion-grid>
</app-layout-modal>
