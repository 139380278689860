<app-layout-modal (closed)="closeModal()" (backed)="showDrops=false" [icon]="showDrops?'back':'close'">
  <ion-split-pane contentId="main-content" class="pane">
    <ion-menu contentId="main-content" type="overlay">
      <ion-content>
        <ion-list id="inbox-list" class="flex-col ion-justify-content-around h-full">
          <div>
            <ion-button color="primary"
            fill="clear"
            class="close-menu"
            (click)="toggleMenu()">
            <ion-icon size="large"
                      name="chevron-back-circle-outline"></ion-icon>
            </ion-button>
          </div>
          <ion-list-header class="ion-justify-content-center">
            <ion-item class="w-full profile-img" lines="none">
              <ng-container *ngIf="user?.detail?.avatar; else noAvatar">
                <ion-avatar class="avatar" slot="start">
                  <img class="avatar-img" [src]="user?.detail?.avatar?.url" alt="Profile img">
                  <ion-icon class="edit pointer"
                            src="assets/images/general/edit.svg"
                            (click)="uploadAvatar()"></ion-icon>
                </ion-avatar>
              </ng-container>
              <ng-template #noAvatar>
                <ion-avatar class="avatar" slot="start">
                  <app-temp-avatar size="medium" [user]="user"></app-temp-avatar>
                  <ion-icon class="edit pointer"
                            src="assets/images/general/edit.svg"
                            (click)="uploadAvatar()"></ion-icon>
                </ion-avatar>
              </ng-template>
              <ion-label class="profile-info">
                <ion-text>{{'shared.hello' | transloco}}!</ion-text>
                <ion-text class="bold username">{{user?.firstName}} {{user?.lastName}}</ion-text>
              </ion-label>
            </ion-item>
            <!-- <ion-button color="primary"
                        fill="clear"
                        class="close-menu"
                        (click)="toggleMenu()">
              <ion-icon size="large"
                        name="chevron-back-circle-outline"></ion-icon>
            </ion-button> -->
          </ion-list-header>
          <div style="margin-top: 30px;">
            <ion-menu-toggle class="pointer"
                             auto-hide="false"
                             *ngFor="let p of modalPages; let i = index">
              <ion-item (click)="select(p)" lines="none" detail="false" [class.selected]="p.selected">
                <ion-label>{{ p.titleTranslate | transloco}}</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </div>
          <ion-item class="pointer" lines="none" detail="false">
            <ion-label class="bold" color="light" (click)="logout()">{{'shared.logout' | transloco}}</ion-label>
          </ion-item>
        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-content class="content" id="main-content">
      <!-- <ion-button color="primary"
                  fill="clear"
                  class="open-menu"
                  (click)="toggleMenu()">
        <ion-icon size="large" name="chevron-forward-circle-outline"></ion-icon>
      </ion-button> -->
      <!-- Dashboard -->
      <ng-container *ngIf="selected === 'Dashboard'">
        <app-profile-dashboard [packs]="packs"
                               [showDrops]="showDrops"
                               (viewDrops)="showDrops=true"
                               [pendingBids]="pendingBids"
                               [pastBids]="pastBids"
                               [loadingPack]="loadingPack"
                               [userDetail]="userDetail"
                               (showMyCollection)="showMyCollection()"></app-profile-dashboard>
      </ng-container>

      <!-- Account -->
      <ng-container *ngIf="selected === 'My account'">
        <app-profile-account></app-profile-account>
      </ng-container>

      <ng-container *ngIf="selected === 'My collection'">
        <app-profile-collection (loadMoreData)="loadPacks($event)" [packsPagination]="packsPagination" [packs]="packs"></app-profile-collection>
      </ng-container>
    </ion-content>
  </ion-split-pane>
</app-layout-modal>
