import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Router} from '@angular/router';

@Component({
  selector: 'app-payment-error-modal',
  templateUrl: './payment-error-modal.component.html',
  styleUrls: ['./payment-error-modal.component.scss'],
})
export class PaymentErrorModalComponent implements OnInit {
  id: number;

  constructor(private modalController: ModalController,
              private router: Router) {
  }

  ngOnInit() {
  }

  async closeModal() {
    await this.modalController.dismiss({});
    await this.router.navigate(['fan', 'auction', this.id]);
    window.location.reload();
  }

}
