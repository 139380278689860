<ion-row class="flex justify-center">
  <ion-col sizeXs="6" sizeMd="8">
    <div class="bg-rounded bg-gray h-full">
      <ion-row class="auction ion-padding">
        <ion-col class="order-image" sizeMd="4">
          <ion-img [src]="pendingBid?.auction?.pack?.thumbnail?.url"></ion-img>
          <div class="auction-title">
            <span class="flex flex-col">
              <ion-text color="light" class="bold">{{ pendingBid?.auction?.pack?.name }}</ion-text>
            </span>
          </div>
        </ion-col>
        <ion-col class="order-description" sizeMd="8">
          <ion-text class="auction-description" color="light">{{pendingBid?.auction?.pack?.shortDescription }}</ion-text>
        </ion-col>
      </ion-row>
    </div>
  </ion-col>
  <ion-col sizeXs="6" sizeMd="4" class="details-container">
    <div class="bg-rounded bg-gray rounded-wrapper">
      <ion-text style="margin-top: 10px;" class="flex justify-center" color="light" *ngIf="pendingBid?.auction?.is_finished == false">{{'drops.remaining' | transloco}}
      </ion-text>
      <app-countdown [showText]="false" [timestamp]="pendingBid?.auction?.end_date" background="inherit" fontSize="1rem"
        timeFontSize="1rem" *ngIf="pendingBid?.auction?.is_finished == false"></app-countdown>
      <ion-text *ngIf="pendingBid?.auction?.is_finished" class="ion-text-center" color="light">                
        <ion-text *ngIf="pendingBid?.is_winner">Subasta ganada</ion-text>
        <ion-text *ngIf="pendingBid?.is_winner == false">Subasta perdida</ion-text>
        <ion-text *ngIf="pendingBid?.status == 0"><br>Cobro no procesado</ion-text>
        <ion-text *ngIf="pendingBid?.status == 1"><br>Cobro exitoso</ion-text>
        <ion-text *ngIf="pendingBid?.status == 2"><br>Error en el pago</ion-text>
        <ion-text *ngIf="pendingBid?.status == 3"><br>Cobro capturado</ion-text>
        <ion-text *ngIf="pendingBid?.status == 4"><br>Cobro anulado</ion-text>
        <ion-text *ngIf="pendingBid?.status == 5"><br>Cobro devuelto</ion-text>
      </ion-text>
    </div>
    <div class="bg-rounded bg-gray rounded-wrapper detailed-amounts">
      <ion-text class="flex justify-center" color="light">{{'shared.my_order' | transloco}}
      </ion-text>
      <ion-text *ngIf="pendingBid?.status == 0" color="light"> (No procesada)</ion-text>
      <ion-text *ngIf="pendingBid?.status == 1" color="light"> (Procesada)</ion-text>
      <ion-text *ngIf="pendingBid?.status == 2" color="light"> (Error)</ion-text>
      <ion-text *ngIf="pendingBid?.status == 3" color="light"> (Reservada)</ion-text>
      <ion-text *ngIf="pendingBid?.status == 4" color="light"> (Cancelada)</ion-text>
      <ion-text *ngIf="pendingBid?.status == 5" color="light"> (Devuelta)</ion-text>
      <ion-text *ngIf="pendingBid?.status == 2 || pendingBid?.status == 4 || pendingBid?.status == 0" class="flex justify-center bid" color="light" style="text-decoration: line-through;">{{'currency.ars' | transloco}} {{pendingBid?.amount | number: '1.2-2'}}</ion-text>
      <ion-text *ngIf="pendingBid?.status == 1 || pendingBid?.status == 3 || pendingBid?.status == 5" class="flex justify-center bid" color="light">{{'currency.ars' | transloco}} {{pendingBid?.amount | number: '1.2-2'}}</ion-text>
    </div>
  </ion-col>
</ion-row>
