import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {environment} from '../../environments/environment';
import {LoginResponse, User, UserToken} from '../shared/interface/user';
import {catchError, Observable, of, Subject, tap} from 'rxjs';
import {BaseResponse} from '../shared/interface/response';
import {AppConstants} from '../app.constants';
import {Router} from '@angular/router';
import {ReCaptchaV3Service, ScriptService} from 'ngx-captcha';
import to from 'await-to-js';
import {CaptchaV3} from '../shared/interface/captcha';


@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseService<User> {
  captchaKeyV3 = environment.captchaKeyV3;
  userInfoUpdated: Subject<User> = new Subject<User>();

  constructor(private httpClient: HttpClient,
              private router: Router,
              private reCaptchaV3Service: ReCaptchaV3Service,
              private reCaptchaService: ScriptService) {
    super(httpClient);
    this.url = `${environment.apiServer}`;
  }

  updateInfo(updatedInfo: any) {
    const user: User = JSON.parse(localStorage.getItem(AppConstants.keys.user));
    if (user) {
      user.lastName = updatedInfo.lastName;
      user.firstName = updatedInfo.firstName;
      user.email = updatedInfo.email;
      user.birthday = updatedInfo.birthday;
      user.gender = updatedInfo.gender;
      user.city = updatedInfo.city;
      user.phoneNumber = updatedInfo.phoneNumber;
      if (user.detail && updatedInfo.detail && updatedInfo.detail.avatar) {
        user.detail.avatar = updatedInfo.detail.avatar;
      }
      localStorage.setItem(AppConstants.keys.user, JSON.stringify(user));
      this.userInfoUpdated.next(user);
    }
  }

  checkIfLogged() {
    return localStorage.getItem(AppConstants.keys.token);
  }

  getUser(): User {
    const user = JSON.parse(localStorage.getItem(AppConstants.keys.user));
    return user;
  }

  logout() {
    localStorage.removeItem(AppConstants.keys.accessToken);
    localStorage.removeItem(AppConstants.keys.refreshToken);
    localStorage.removeItem(AppConstants.keys.user);
    localStorage.removeItem(AppConstants.keys.token);
  }

  checkCaptcha(token: string, isV3?: boolean) {
    return this.httpClient.post<any>(`${this.url}/check/captcha `, {
      response: token,
      isV3: isV3 != null ? isV3 : false
    });
  }

  login(email: string, password: string): Observable<LoginResponse> {
    return this.httpClient.post<LoginResponse>(`${this.url}/login`, {
      email,
      password
    }).pipe(
      tap(response => this.storeUserAndTokens(response.data.data))
    );
  }

  async validateUserNotRobot() {
    this.reCaptchaService.cleanup();
    this.reCaptchaV3Service.execute(this.captchaKeyV3, 'login', async (token) => {
      // console.log('@==>', 'v3 captcha pre - validation: ', token);
      const [error, response] = await to<CaptchaV3>(this.checkCaptcha(token, true).toPromise());
      if (response) {
        // console.log('@==>', 'v3 captcha validation: ', response.data.score);
        if (response.data.score < 0.45) {
          await this.router.navigate(['/fan/home']);
        }
      }
    }, {
      useGlobalDomain: false
    });
  }

  refreshToken(): Observable<LoginResponse> {
    return this.httpClient.post<LoginResponse>(`${this.url}/users/refresh-token`, {
      refreshToken: this.getLoggedInfo().refreshToken.token
    }).pipe(
      tap((response: any) => {
        response.data.refreshToken = this.getLoggedInfo().refreshToken;
        this.storeTokens(response.data);
      }),
      catchError(error => {
        this.router.navigate(['/user']);
        return of(null);
      })
    );
  }

  getLoggedInfo(): UserToken {
    return {
      idToken: JSON.parse(localStorage.getItem(AppConstants.keys.token)),
      accessToken: JSON.parse(localStorage.getItem(AppConstants.keys.accessToken)),
      refreshToken: JSON.parse(localStorage.getItem(AppConstants.keys.refreshToken)),
    };
  }

  storeTokens(tokens) {
    localStorage.setItem(AppConstants.keys.token, JSON.stringify(tokens.idToken));
    localStorage.setItem(AppConstants.keys.accessToken, JSON.stringify(tokens.accessToken));
    localStorage.setItem(AppConstants.keys.refreshToken, JSON.stringify(tokens.refreshToken));
  }

  storeUserAndTokens(response) {
    localStorage.setItem(AppConstants.keys.user, JSON.stringify(response));
    this.storeTokens(response.tokens);
  }

  register(registerData: any): Observable<LoginResponse> {
    registerData.gender = Number(registerData.gender);
    registerData.city = Number(registerData.city);
    return this.httpClient.post<LoginResponse>(`${this.url}/register`, registerData);
  }

  validateUserIsLogged(): boolean {
    return !!localStorage.getItem('token');
  }

  forgotPassword(email: string): Observable<any> {
    return this.httpClient.post<BaseResponse<any>>(`${this.url}/users/forgot-password`, {email});
  }

  resetPassword(data: any): Observable<any> {
    return this.httpClient.post<BaseResponse<any>>(`${this.url}/users/recover-password`, data);
  }

  verifyUser(data: any): Observable<any> {
    return this.httpClient.post<BaseResponse<any>>(`${this.url}/verify-user`, data);
  }

}
