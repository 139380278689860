<ion-grid class="footer-container">
  <ion-row class="action-footer">
    <ion-col class="ion-padding-horizontal text-align: left" sizeXs="12" sizeMd="3" sizeLg="4">
      <h3>DILLOM.RIP</h3>
      <!-- <ion-img src="assets/images/logo-dillom.png" style="height: 2.2rem; margin-top: 2rem;"></ion-img> -->
      <!-- <div class="social-media">
        <a
          href="https://discord.gg/47yefZ5x"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ion-icon name="logo-youtube"></ion-icon>
        </a>
        <a
          href="https://www.instagram.com/bohemiangroovecorp"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ion-icon name="logo-instagram"></ion-icon>
        </a>
      </div> -->
      <p class="footer-legal color-gray">2023 Bohemian Groove Corp®</p>
    </ion-col>

    <ion-col
      class="ion-padding-horizontal ion-margin-top"
      sizeXs="12"
      sizeMd="9"
      sizeLg="8"
    >
      <!-- <div class="actions">
        <h1 class="title">{{ 'footer.account' | transloco | uppercase }}</h1>
        <a class="link" *ngIf="!isLoggedIn()" (click)="goToLogin()">{{ 'footer.login' | transloco }}</a>
        <a class="link" routerLink="/user/sign-up">{{ 'footer.createAccount' | transloco }}</a>
        <a class="link" routerLink="">{{ 'footer.collection' | transloco }}</a>
      </div>

      <div class="actions">
        <h1 class="title">{{ 'footer.explore' | transloco | uppercase }}</h1>
        <a class="link" routerLink="/fan/auction">{{ 'footer.drops' | transloco }}</a>
        <a class="link" routerLink="/fan/marketplace">{{ 'footer.marketplace' | transloco }}</a>
      </div> -->

      <div class="actions hide-mobile">
        <h1 class="title">{{ "footer.contact" | transloco | uppercase }}</h1>
        <a class="link" routerLink="/fan/faq">{{ "footer.faq" | transloco }}</a>
        <a class="link" href="https://discord.gg/4VAm9kUgDy" target="_blank">Discord</a>
      </div>

      <div class="actions hide-mobile">
        <h1 class="title">{{ "footer.about" | transloco | uppercase }}</h1>
        <a class="link" href="">Dillom.RIP</a>
        <a class="link" href="#/fan/terms">{{
          "footer.termsAndConditions" | transloco
        }}</a>
        <a class="link" href="#/fan/politics">{{
          "footer.privacyPolicy" | transloco
        }}</a>
      </div>
    </ion-col>
  </ion-row>

  <ion-row class="w-926 ion-justify-content-end ion-padding">
    <ion-col sizeXs="12" class="ion-justify-content-center ion-padding w-517">
      <p class="legal">
        <a href="https://fanweb.io/" target="_blank" rel="noopener noreferrer">
          <img
            class="logo-fan"
            src="assets/images/logo-fan.svg"
            alt="Logo fan"
          />
        </a>
      </p>
    </ion-col>
  </ion-row>
</ion-grid>
