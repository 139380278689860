/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Pack} from '../../../../shared/interface/pack';
import {PendingBid, UserDetail} from '../../../../shared/interface/user';

@Component({
  selector: 'app-profile-dashboard',
  templateUrl: './profile-dashboard.component.html',
  styleUrls: ['./profile-dashboard.component.scss'],
})
export class ProfileDashboardComponent implements OnInit {
  @Output() showMyCollection: EventEmitter<any> = new EventEmitter<any>();
  @Output() viewDrops: EventEmitter<any> = new EventEmitter<any>();
  @Input() packs: Pack[];
  @Input() pendingBids: PendingBid[];
  @Input() pastBids: PendingBid[];
  @Input() userDetail: UserDetail;
  @Input() showDrops = false;
  @Input() loadingPack = false;
  
  constructor() {
  }

  ngOnInit() {
  }

}
