<div class="countdown-wrapper" [ngClass]="[type]">
  <ion-text *ngIf="showText" class="countdown-text font-editorial">
    <label [ngStyle]="{'font-size': fontLabelSize}">{{'countdown.auction_ends' | transloco}}</label>
  </ion-text>
  <div class="countdown font-editorial"
       [ngStyle]="{'font-size': fontSize}"
       [ngClass]="[type]">
    <span [ngStyle]="{'font-size': timeFontSize}"> {{daysToDay}}</span>d
    <span [ngStyle]="{'font-size': timeFontSize}"> {{hoursToDay}}</span>h
    <span [ngStyle]="{'font-size': timeFontSize}"> {{minutesToDay}}</span>m
    <span [ngStyle]="{'font-size': timeFontSize}"> {{secondsToDay}}</span>s
  </div>
</div>
