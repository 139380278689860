import {BaseResponse} from './response';
import {Pack} from './pack';
import {Auction} from './auction';
import {City} from './country';
import {Media} from './media';

export interface User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  birthday: string;
  gender: number;
  // country: number;
  city: City;
  tokens: UserToken;
  detail: UserDetails;
  dni: string;
  phoneNumber: string;
}

export interface UserDetails {
  id: number;
  avatar: Media;
}

export interface UserToken {
  idToken: UserTokenDetail;
  accessToken: UserTokenDetail;
  refreshToken: UserTokenDetail;
}

export interface UserTokenDetail {
  expiration: number;
  token: string;
}

export interface UserDetail {
  id: number;
  address: string;
  // eslint-disable-next-line id-blacklist
  number: string;
  floor: string;
  apartment: string;
  zipCode: string;
  city: City;
  state: string;
  smsVerification: boolean;
  twoStepAuthentication: boolean;
  receiveEmailAlerts: boolean;
  secretMode: boolean;
  points: string;
  avatar: Media;
}

export interface LoginResponse extends BaseResponse<any> {
  data: {
    data: User;
  };
}

export interface MintedPack {
  id: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  is_used: boolean;
  pack: Pack;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  token_id: number;
}

export interface PendingBid {
  id: number;
  amount: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  is_winner: boolean;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  created_at: string;
  status: number;
  auction: Auction;

  isDeleted?: boolean;
}
