<ion-grid class="drop-grid">

  <ion-row class="ion-margin">
    <div class="view-header">
      <h3 class="title">{{'profile_modal.pages.my_collection' | transloco}}</h3>
      <!-- <a class="link-view-all-collection" (click)="showMyCollection.emit()">{{'profile_modal.dashboard.view_all' | transloco}}</a> -->
    </div>

    <!-- <ion-badge class="flex flex-col points bg-gray">
      <ion-text class="font-iner font-size-small">{{userDetail?.points ? userDetail?.points : 0}}</ion-text>
      <ion-text>{{'profile_modal.dashboard.earned' | transloco}}</ion-text>
    </ion-badge> -->

  </ion-row>

  <!-- Listado de pack -->
  <ion-row *ngIf="loadingPack">
    <ion-col class="text-center">
      <ion-spinner name="dots" color="light"></ion-spinner>
    </ion-col>
  </ion-row> 
  <ion-row class="packs-wrapper  ion-margin-horizontal" *ngIf="!loadingPack">
    <!-- ! Packs empty -->
    <app-mini-pack *ngFor="let item of packs" [pack]="item"></app-mini-pack>
    <!-- Pack empty -->
    <div class="pack-empty w-full" *ngIf="packs.length===0 && !loadingPack">
      <p class="text-center" style="color: gray">
        {{'profile_modal.dashboard.collection_empty' | transloco}}
      </p>
      <!-- <a href="#">{{'profile_modal.dashboard.see_auctions' | transloco}}</a> -->
    </div>
  </ion-row>

  <!-- <ion-row class="ion-margin ion-padding-start">
    <div class="view-header">
      <h3 class="title">Mis órdenes</h3>
      <a class="link-view-all-orders" (click)="showDrops=true;viewDrops.emit()">{{'profile_modal.dashboard.view_all' | transloco}}</a>
    </div>
  </ion-row> -->
  <!-- <section class="auction-list ion-margin">
    <ng-container *ngFor="let item of pendingBids">
      <app-mini-auction [pendingBid]="item"></app-mini-auction>      
    </ng-container>
  </section> -->
</ion-grid>

<ion-grid class="drop-grid">
  <!-- <ion-row class="ion-margin">
    <ion-text color="light" class="bold title ion-margin">{{'profile_modal.dashboard.drops_in_process' | transloco}}</ion-text>
  </ion-row> -->
  <ion-row class="ion-margin-horizontal">
    <div class="view-header">
      <h3 class="title">Mis órdenes</h3>
    </div>
  </ion-row>
  <section class="drop-list">
    <ng-container *ngFor="let item of pendingBids">
      <app-mini-auction [pendingBid]="item"></app-mini-auction>
    </ng-container>
  </section>
  <ion-row *ngIf="loadingPack">
    <ion-col class="text-center">
      <ion-spinner name="dots" color="light"></ion-spinner>
    </ion-col>
  </ion-row> 
  <div class="pack-empty w-full" *ngIf="pendingBids.length===0 && !loadingPack">
    <p class="text-center" style="color: gray">
      Aún no posees apuestas realizadas
    </p>
    <!-- <a href="#">{{'profile_modal.dashboard.see_auctions' | transloco}}</a> -->
  </div>
  <!-- <ion-row class="ion-margin">
    <ion-text color="light" class="bold title ion-margin">{{'profile_modal.dashboard.past_drops' | transloco}}</ion-text>
  </ion-row>
  <section class="drop-list">
    <ng-container *ngFor="let item of pastBids">
      <app-mini-auction [pendingBid]="item"></app-mini-auction>
    </ng-container>
  </section>  -->
</ion-grid>
