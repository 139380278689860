/* eslint-disable max-len */
import { Component, ElementRef, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
})
export class TermsComponent implements OnInit {
  reachedBottomScroll = false;

  terms = [
    {
      title: 'terms.terms1.title',
      text: 'terms.terms1.text'
    },
    {
      title: 'terms.terms2.title',
      text: 'terms.terms2.text'
    },
    {
      title: 'terms.terms3.title',
      text: 'terms.terms3.text'
    },
    {
      title: 'terms.terms4.title',
      text: 'terms.terms4.text'
    },
    {
      title: 'terms.terms5.title',
      text: 'terms.terms5.text'
    },
    {
      title: 'terms.terms6.title',
      text: 'terms.terms6.text'
    },
    {
      title: 'terms.terms7.title',
      text: 'terms.terms7.text'
    },
    {
      title: 'terms.terms8.title',
      text: 'terms.terms8.text'
    },
    {
      title: 'terms.terms9.title',
      text: 'terms.terms9.text'
    },
    {
      title: 'terms.terms10.title',
      text: 'terms.terms10.text'
    },
    {
      title: 'terms.terms11.title',
      text: 'terms.terms11.text'
    },
    {
      title: 'terms.terms12.title',
      text: 'terms.terms12.text'
    },
    {
      title: 'terms.terms13.title',
      text: 'terms.terms13.text'
    },
  ];

  constructor(
    private utilService: UtilsService,
    private el: ElementRef,
  ) {
  }

  ngOnInit() {
    const row = document.getElementById('row-terms');
    row.onscroll = () => {
      // Indicates the scroll have reached end of row container
      if ((row.clientHeight + row.scrollTop) > row.scrollHeight - 10) {
        this.reachedBottomScroll = true;
      }
    };
  }

  close(response: boolean) {
    this.utilService.dismissModal(response);
  }

}
