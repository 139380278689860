import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../services/auth.service';
import { UtilsService } from '../../../services/utils.service';
import to from 'await-to-js';
import { LoginResponse } from '../../interface/user';
import { firstValueFrom, Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ModalController } from '@ionic/angular';
import { PageActionsService } from '../../../services/page-actions.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-signing',
  templateUrl: './signing.component.html',
  styleUrls: ['./signing.component.scss'],
})
export class SigningComponent implements OnInit, OnDestroy {
  showPassword = false;
  captchaResolved = false;

  signInForm: UntypedFormGroup;
  subscription: Subscription;
  captchaKeyV2 = environment.captchaKey;

  constructor(
    private router: Router,
    private utils: UtilsService,
    private builder: UntypedFormBuilder,
    private authService: AuthService,
    private modalController: ModalController,
    private pageActionsService: PageActionsService,
    private translocoService: TranslocoService,
  ) {
    this.signInForm = this.builder.group({
      email: ['', [Validators.email, Validators.required]],
      password: ['', Validators.required],
    });
  }

  get email() {
    return this.signInForm.get('email');
  }

  get password() {
    return this.signInForm.get('password');
  }

  async closeModal() {
    await this.modalController.dismiss({});
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  async login() {
    const formData = this.signInForm.value;
    const email = formData.email;
    const password = formData.password;
    await this.utils.showLoading(null, 10 * 1000);
    const [error, response] = await to<LoginResponse, any>(this.authService.login(email, password).toPromise());
    await this.utils.dismissLoading();
    if (response) {
      await this.closeModal();
      this.pageActionsService.loginSuccessful();
    } else {
      const errorCode = `login.errors.${error.error.customCode}`;
      const msg = await firstValueFrom(this.translocoService.selectTranslate(errorCode));
      await this.utils.showAlert('Ups!', msg, ['Ok']);
    }
  }

  txtButton = 'Inicar sesión';
  async resolve(event: string) {
    if (event) {
     // await this.utils.showLoading(null, 10 * 1000);
     this.txtButton = 'Validando reCaptcha...';
      const [error, response] = await to(this.authService.checkCaptcha(event).toPromise());
     // await this.utils.dismissLoading();
     this.txtButton = 'Inicar sesión';
      if (response) {
        this.captchaResolved = (event !== null) ? true : false;
      }
    } else {
      await this.utils.showAlert('Ups!', 'Try again, please', ['Ok']);
    }
  }

  errored() {
    this.captchaResolved = false;
  }

  async getAnewPassword(event: any) {
    event.preventDefault();
    await this.modalController.dismiss();
    await this.router.navigate(['/user/recover-password']);
  }

  async getAnewAccount(event: any) {
    event.preventDefault();
    await this.modalController.dismiss();
    await this.router.navigate(['/user/sign-up']);
  }

}
