import { Component, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';
import { UserService } from '../../../services/user.service';
import to from 'await-to-js';
import { Pack } from '../../../shared/interface/pack';
import { AuthService } from '../../../services/auth.service';
import { PendingBid, User, UserDetail } from '../../../shared/interface/user';
import { MenuController, ModalController, NavParams, ToastController } from '@ionic/angular';
import { Page } from '../../../shared/interface/page';
import { AvatarUploadComponent } from './avatar-upload/avatar-upload.component';
import { AppConstants } from '../../../app.constants';
import { BaseResponse } from '../../../shared/interface/response';
import { ActivatedRoute } from '@angular/router';
import { AuctionService } from 'src/app/services/auction.service';

@Component({
  selector: 'app-user-profile-modal',
  templateUrl: './user-profile-modal.component.html',
  styleUrls: ['./user-profile-modal.component.scss'],
})
export class UserProfileModalComponent implements OnInit {
  selected = 'Dashboard';
  modalPages: Page[] = [
    {title: 'Dashboard', selected: true, titleTranslate: 'profile_modal.pages.dashboard'},
    // {title: 'My collection', selected: false, titleTranslate: 'profile_modal.pages.my_collection'},
    // {title: 'My points', selected: false, titleTranslate: 'profile_modal.pages.my_points'},
    {title: 'My account', selected: false, titleTranslate: 'profile_modal.pages.my_account'},
    // {title: 'Help', selected: false, titleTranslate: 'profile_modal.pages.help'},
  ];
  packs: Pack[] = [];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  packsPagination: { current_page: number; listed: number; total: number; total_pages: number };
  pendingBids: PendingBid[] = [];
  pastBids: PendingBid[] = [];
  user: User;
  userDetail: UserDetail;
  showDrops = false;

  constructor(private route: ActivatedRoute,
              private utilService: UtilsService,
              private userService: UserService,
              private authService: AuthService,
              private modalController: ModalController,
              private menuController: MenuController,
              private auctionService: AuctionService,
              private navParams: NavParams,
              private toastController: ToastController) {
      const selected = this.navParams.get('selected');
    
      console.log(selected);
  }

  loadingPack = false;
  async ngOnInit() {
    this.loadingPack = true;
    this.user = this.authService.getUser();    
    //await this.utilService.showLoading(null, 10 * 1000);
    const [_errorPendingBid, responseBids] = await to(this.userService.pendingBids(this.user.id).toPromise());
    //const [_errorPendingBidPast, responseBidsPast] = await to(this.userService.pastBids(this.user.id).toPromise());
    const [_errorInfo, responseInfo] = await to(this.userService.info(this.user.id).toPromise());    
    if (responseBids) {
      this.pendingBids = responseBids.data.data;
      //this.pastBids = responseBids.data.data.filter(i => i.auction.is_finished);
    }
    if (responseInfo) {
      this.userDetail = responseInfo.data;
    }
    this.loadPacks();
    this.route.queryParams
      .subscribe(params => {
        if (!!params.showProfilePage) {
          this.select({title: 'Dashboard', selected: true, titleTranslate: 'profile_modal.pages.dashboard'});
        }
      });
    this.loadingPack = false;
  }

  closeModal() {
    this.utilService.dismissModal();
  }

  select(page: Page) {
    this.selected = page.title;
    if (this.selected === 'Dashboard') {
      this.showDrops = true;
    }
    this.modalPages = this.modalPages.map((e) => ({
      ...e,
      selected: e.title === page.title,
    }));

    if (this.selected === 'My points' || this.selected === 'Help') {
      this.upComming();
    }
  }

  async upComming() {
    const toast = await this.toastController.create({
      message: 'Coming soon!',
      duration: 2000,
    });

    await toast.present();
  }

  async logout() {
    await this.modalController.dismiss({});
    this.authService.logout();
    this.utilService.goPage('fan/auction');
    this.authService.userInfoUpdated.next(null);
  }

  async toggleMenu() {
    if (await this.menuController.isOpen()) {
      await this.menuController.close();
    } else {
      await this.menuController.open();
    }
  }

  async uploadAvatar() {
    const modal = this.utilService.showModal(
      AvatarUploadComponent,
      [...AppConstants.modal.sizes.uploadFile, 'fixed-height-small-modal'],
      {
        data: {
          ...AppConstants.files.thumbnail,
          file: this.user ? this.user.detail?.avatar : null,
        },
      }
    );
    const data = (await modal).onDidDismiss();
    const thumbnailData = (await data).data;
    if (thumbnailData) {
      const avatar = {
        file: thumbnailData.fileBase64,
        filename: thumbnailData.fileName,
      };
      const payload: any = {
        avatar
      };
      await this.utilService.showLoading(null, 10 * 1000);
      const [_, response] = await to<BaseResponse<UserDetail>>(this.userService.saveInfo(this.user.id, payload).toPromise());
      await this.utilService.dismissLoading();
      if (response) {
        const userDetails: UserDetail = response.data;
        this.user.detail.avatar = userDetails.avatar;
        this.authService.updateInfo(this.user);
      }
    }
  }

  showMyCollection() {
    this.selected = 'My collection';
    this.modalPages.forEach(item => item.selected = false);
    this.modalPages[1].selected = true;
  }
  
  async loadPacks(page: number = 1) {
    console.log('@==> page: ', page);

    // const [_, responseMintedPacks] = await to(this.userService
    //   .mintedPacksPaginate(this.user.id, page, 5, '', {}, '-id').toPromise());
    
    this.pendingBids.forEach(async(bid) => {
      if(bid.is_winner == true && bid.auction.is_finished == true){        
        this.packs.push(bid.auction.pack);
      }
    });
    // if (responseMintedPacks) {
    //   console.log('@==> responseMintedPacks', responseMintedPacks);
    //   const filterPacks = responseMintedPacks.data.data.map((item) => item.pack);
    //   this.packs.push(...filterPacks);
    //   // Update paginator
    //   this.packsPagination = {
    //     // eslint-disable-next-line @typescript-eslint/naming-convention
    //     current_page: responseMintedPacks.data.current_page,
    //     listed: responseMintedPacks.data.listed,
    //     total: responseMintedPacks.data.total,
    //     // eslint-disable-next-line @typescript-eslint/naming-convention
    //     total_pages: responseMintedPacks.data.total_pages
    //   };
    // }
    //return responseMintedPacks;
  }
}
