import {Component, Input, OnInit} from '@angular/core';
import {User} from '../../interface/user';

@Component({
  selector: 'app-temp-avatar',
  templateUrl: './temp-avatar.component.html',
  styleUrls: ['./temp-avatar.component.scss'],
})
export class TempAvatarComponent {
  @Input() user: User;
  @Input() size: 'medium' | 'normal' | 'big';

  get nameInitials() {
    return this.user.firstName.length > 0 && this.user.lastName.length > 0 ?
      `${this.user.firstName.charAt(0)}${this.user.lastName.charAt(0)}` : '';
  }
}
